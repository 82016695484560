import { EventRepository } from '../repositories/EventRepository';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class StoreLocatorClickedUseCase {
    private eventRepository: EventRepository;
    private storeId: string;
    private storeName: string;
    private eventLabel: string;

    constructor(storeId: string, storeName: string, eventLabel: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.storeId = storeId;
        this.storeName = storeName;
        this.eventLabel = eventLabel;
    }

    public execute = async () => {
        const storeLocatorClickedPayload = {
            event_name: `${this.eventLabel}`,
            event_action: `${this.eventLabel}`,
            event_label: `${this.storeName} -${this.storeId}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: this.eventLabel,
            type: 'link',
            payload: storeLocatorClickedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
