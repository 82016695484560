import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class CreateAccountAttemptUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = () => {
        const createAccountAttemptPayload = {
            event_name: 'create_account_attempt',
            event_category: 'account',
            event_action: 'create account attempt'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.CREATE_ACCOUNT_ATTEMPT,
            type: 'link',
            payload: createAccountAttemptPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
