import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { EventSubmitter } from '../EventSubmitter';
import { AnalyticsDriver } from '../AnalyticsDriver';
import { PageViewUseCase } from './PageViewUseCase';

export class AnalyticsLoadedUseCase {
    private eventSubmitter: EventSubmitter;
    private analyticsDriver: AnalyticsDriver;

    constructor() {
        this.eventSubmitter = diContainer.get(serviceNames.eventSubmitter);
        this.analyticsDriver = diContainer.get(serviceNames.analyticsDriver);
    }

    public execute = () => {
        // TODO: load user data into data layer
        // TODO: load cart data into data layer
        new PageViewUseCase().execute();
        this.analyticsDriver.enable();
        this.eventSubmitter.enableAnalytics();
    };
}
