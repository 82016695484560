import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class SearchAddressSubmittedUseCase {
    private eventRepository: EventRepository;
    private address: string;

    constructor(address: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.address = address;
    }

    public execute = async () => {
        const searchAddressSubmittedPayload = {
            event_name: 'search address submitted',
            event_action: 'search address submitted',
            event_label: `${this.address}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SEARCH_ADDRESS_SUBMITTED,
            type: 'link',
            payload: searchAddressSubmittedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
