export enum ReviewsEventAction {
    READ_REVIEW = 'review - read',
    WRITE_REVIEW = 'review - write - start',
    HELPFUL_YES = 'review - helpful - thumbs up',
    HELPFUL_NO = 'review - helpful - thumbs down',
    FLAG_REVIEW = 'review - helpful - flag this review',
    REVIEWS_FILTER = 'review - filter',
    REVIEWS_SEARCH = 'review - search',
    ASK_AND_ANSWER = 'ask answer - read',
    ASK_AND_ANSWER_SUBMIT = 'ask answer - ask a question - end',
    ASK_AND_ANSWER_SEARCH = 'ask answer - ask a question - search',
    ASK_AND_ANSWER_HELPFUL_YES = 'ask answer - helpful - thumbs up',
    ASK_AND_ANSWER_HELPFUL_NO = 'ask answer - helpful - thumbs down',
    ASK_AND_ANSWER_SHOW_MORE = 'ask answer - show more q&a',
    REVIEW_SORT = 'Review - Sort',
    ASK_AND_ANSWER_SORT = 'ask answer - sort',
    REVIEW_OVERLAY_OPEN = 'review overlay - open',
    REVIEW_OVERLAY_CLOSE = 'review overlay - close'
}
