import { on, events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../service-setup/diContainer';
import { AccountPageLandedUseCase } from '../internal/use-cases/AccountPageLandedUseCase';
import { AuthAttemptUseCase } from '../internal/use-cases/AuthAttemptUseCase';
import { AuthFailedUseCase } from '../internal/use-cases/AuthFailedUseCase';
import { CartLandingPageViewedUseCase } from '../internal/use-cases/CartLandingPageViewedUseCase';
import { CartLoggedInUseCase } from '../internal/use-cases/CartLoggedInUseCase';
import { ProductImpressionsUseCase } from '../internal/use-cases/ProductImpressionsUseCase';
import { ProductViewedUseCase } from '../internal/use-cases/ProductViewedUseCase';
import { CreateAccountAttemptUseCase } from '../internal/use-cases/CreateAccountAttemptUseCase';
import { CreateAccountFailUseCase } from '../internal/use-cases/CreateAccountFailUseCase';
import { CreateAccountSuccessUseCase } from '../internal/use-cases/CreateAccountSuccessUseCase';
import { ItemAddedUseCase } from '../internal/use-cases/ItemAddedUseCase';
import { LoggedInSuccessfulUseCase } from '../internal/use-cases/LoggedInSuccessfulUseCase';
import { ManualOfferCodeAppliedUseCase } from '../internal/use-cases/ManualOfferCodeAppliedUseCase';
import { MppFilteredUseCase } from '../internal/use-cases/MppFilteredUseCase';
import { OrderConfirmationPageLandedUseCase } from '../internal/use-cases/OrderConfirmationPageLandedUseCase';
import { OutboundClickedUseCase } from '../internal/use-cases/OutboundClickedUseCase';
import { PaymentMethodCompletedUseCase } from '../internal/use-cases/PaymentMethodCompletedUseCase';
import { PaymentPageViewedUseCase } from '../internal/use-cases/PaymentPageViewedUseCase';
import { ProductClickedUseCase } from '../internal/use-cases/ProductClickedUseCase';
import { QuickViewUseCase } from '../internal/use-cases/QuickViewUseCase';
import { RemoveItemUseCase } from '../internal/use-cases/RemoveItemUseCase';
import { SampleAddedUseCase } from '../internal/use-cases/SampleAddedUseCase';
import { SearchAddressSubmittedUseCase } from '../internal/use-cases/SearchAddressSubmittedUseCase';
import { ShippingAddressFilledUseCase } from '../internal/use-cases/ShippingAddressFilledUseCase';
import { ShippingOptionSelectedUseCase } from '../internal/use-cases/ShippingOptionSelectedUseCase';
import { ShippingPageViewedUseCase } from '../internal/use-cases/ShippingPageViewedUseCase';
import { StoreLocationSelectedUseCase } from '../internal/use-cases/StoreLocationSelectedUseCase';
import { StoreLocatorClickedUseCase } from '../internal/use-cases/StoreLocatorClickedUseCase';
import { StoreMarkerClickedUseCase } from '../internal/use-cases/StoreMarkerClickedUseCase';
import { StoreTownSelectedUseCase } from '../internal/use-cases/StoreTownSelectedUseCase';
import { SubscribedForEmailsUseCase } from '../internal/use-cases/SubscribedForEmailsUseCase';
import { TransactionFailedUseCase } from '../internal/use-cases/TransactionFailedUseCase';
import { TransactionSuccededUseCase } from '../internal/use-cases/TransactionSuccededUseCase';
import { AnalyticsLoadedUseCase } from '../internal/use-cases/AnalyticsLoadedUseCase';
import { SkuEngravingUseCase } from '../internal/use-cases/SkuEngravingUseCase';
import { GridFilteredUseCase, IAnalyticsFilters } from '../internal/use-cases/GridFilteredUseCase';
import { GridSortedUseCase } from '../internal/use-cases/GridSortedUseCase';
import { ShadeGroupSelectedUseCase } from '../internal/use-cases/ShadeGroupSelectedUseCase';
import { LiveChatInterfaceClickedUseCase } from '../internal/use-cases/LiveChatInterfaceClickedUseCase';
import { ProductComparisonViewedUseCase } from '../internal/use-cases/ProductComparisonViewedUseCase';
import { SocialShareIconTriggeredUseCase } from '../internal/use-cases/SocialShareIconTriggeredUseCase';
import { StoreBookVirtualAppointmentClickedUseCase } from '../internal/use-cases/StoreBookVirtualAppointmentClickedUseCase';
import { SkuPickerInteractionUseCase } from '../internal/use-cases/SkuPickerInteractionUseCase';
import { AccountSidebarMenuOptionClickedUseCase } from '../internal/use-cases/AccountSidebarMenuOptionClickedUseCase';
import { AccountSidebarShowcaseClickedUseCase } from '../internal/use-cases/AccountSidebarShowcaseClickedUseCase';
import { AccountEventAction } from '../constants/Account';
import { AppointmentVirtualSelectedUseCase } from '../internal/use-cases/AppointmentVirtualSelectedUseCase';
import { AppointmentServiceSelectedUseCase } from '../internal/use-cases/AppointmentServiceSelectedUseCase';
import { AppointmentDateTimeSelectedUseCase } from '../internal/use-cases/AppointmentDateTimeSelectedUseCase';
import { AppointmentEditClickedUseCase } from '../internal/use-cases/AppointmentEditClickedUseCase';
import { AppointmentStartedOverUseCase } from '../internal/use-cases/AppointmentStartedOverUseCase';
import { AppointmentArtistSelectedUseCase } from '../internal/use-cases/AppointmentArtistSelectedUseCase';
import { AppointmentStepLoadedUseCase } from '../internal/use-cases/AppointmentStepLoadedUseCase';
import { AppointmentProcessCompletedUseCase } from '../internal/use-cases/AppointmentProcessCompletedUseCase';
import { AppointmentAddedToCalendarUseCase } from '../internal/use-cases/AppointmentAddedToCalendarUseCase';

import {
    SearchProductsQueriedUseCase,
    ISearchProductsQueried
} from '../internal/use-cases/SearchProductsQueriedUseCase';
import {
    SearchTermSelectedUseCase,
    ISearchTermSelected
} from '../internal/use-cases/SearchTermSelectedUseCase';
import { VTOActionUseCase } from '../internal/use-cases/VTOActionUseCase';

import * as routes from '../constants/Routes';
import { ProductNotifyMeTriggeredUseCase } from '../internal/use-cases/ProductNotifyMeTriggeredUseCase';
import { ProductNotifyMeRequestCompletedUseCase } from '../internal/use-cases/ProductNotifyMeRequestCompletedUseCase';

import {
    IAccountSidebarBaseData,
    IAccountSidebarShowcaseData,
    IGenericAnalyticsData,
    IProductViewed,
    IProductImpressions,
    IReviewsData,
    ISmartGiftData,
    ISkinCareProducts,
    IVtoType
} from '../internal/interfaces/IAnalytics';
import { RatingsReviewsUseCase } from '../internal/use-cases/RatingsReviewsUseCase';

import { ReviewsEventAction } from '../constants/RatingsReviews';
import { SmartGiftUseCase } from '../internal/use-cases/SmartGiftUseCase';
import { SmartGiftEventAction } from '../constants/SmartGift';
import { VTO_EVENTS, YOUCAM, YOUCAM_SKINCARE } from '../constants/Vto';
import { ActionType } from '../constants/ActionType';
import { IOfferViewedProps, OfferViewed } from '../internal/use-cases/OfferViewed';
import { IOfferClickedProps, OfferClicked } from '../internal/use-cases/OfferClicked';
import { CartTabTabClicked } from '../internal/use-cases/OffersTabClicked';
import { CartOverlayClosed } from '../internal/use-cases/CartOverlayClosed';
import { CartOverlayOpened } from '../internal/use-cases/CartOverlayOpened';
import { AppointmentLocationSelectedUseCase } from '../internal/use-cases/AppointmentLocationSelectedUseCase';

export interface IOrderDetail {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface IOrderQueryData {
    orderId: string;
    guestToken: string;
    paymentType: string;
}

const SHIPPING_SCREEN_SHIPPING_ADDRESS_SELECTION_CONTEXT =
    'checkout_shipping_screen_shipping_address_selection';
const QUICK_VIEW = 'QV';

export class ServiceBusController {
    private orderQueryData: IOrderQueryData;

    constructor() {
        diContainer.get(serviceNames.outboundLinkListener).init();
    }

    @on(events.ANALYTICS_LOADED)
    public analyticsLoaded = () => {
        const analyticsLoadedUseCase = new AnalyticsLoadedUseCase();
        analyticsLoadedUseCase.execute();
    };

    @on(events.PRODUCT_FULL_VIEWED)
    public productViewedUseCase = (payload: IProductViewed) => {
        new ProductViewedUseCase(payload).execute();
    };

    @on(events.PRODUCT_GRID_VIEWED)
    public productImpressionUseCase = (payload: IProductImpressions) => {
        new ProductImpressionsUseCase(payload).execute();
    };

    @on(events.STORE_DETAILS_CLICKED)
    public StoreDetailsClicked = (payload: { storeId: string; storeName: string }) => {
        const storeLocatorClickedUseCase = new StoreLocatorClickedUseCase(
            payload.storeId,
            payload.storeName,
            events.STORE_DETAILS_CLICKED
        );
        storeLocatorClickedUseCase.execute();
    };

    @on(events.GET_DIRECTIONS_CLICKED)
    public GetDirectionsClicked = (payload: { storeId: string; storeName: string }) => {
        const getDirectionsClickedUseCase = new StoreLocatorClickedUseCase(
            payload.storeId,
            payload.storeName,
            events.GET_DIRECTIONS_CLICKED
        );
        getDirectionsClickedUseCase.execute();
    };

    @on(events.STORE_INFO_CLICKED)
    public StoreInfoClicked = (payload: { storeId: string; storeName: string }) => {
        const storeInfoClickedUseCase = new StoreLocatorClickedUseCase(
            payload.storeId,
            payload.storeName,
            events.STORE_INFO_CLICKED
        );
        storeInfoClickedUseCase.execute();
    };

    @on(events.STORE_MARKER_CLICKED)
    public StoreMarkerClicked = (payload: { storeId: string }) => {
        const storeMarkerClickedUseCase = new StoreMarkerClickedUseCase(payload.storeId);
        storeMarkerClickedUseCase.execute();
    };

    @on(events.STORE_PHONE_CLICKED)
    public StorePhoneClicked = (payload: { storeId: string; storeName: string }) => {
        const storePhoneClickedUseCase = new StoreLocatorClickedUseCase(
            payload.storeId,
            payload.storeName,
            events.STORE_PHONE_CLICKED
        );
        storePhoneClickedUseCase.execute();
    };

    @on(events.BOOK_APPOINTMENT_CLICKED)
    public BookAppointmentClicked = (payload: { storeId: string; storeName: string }) => {
        const bookAppointmentClickedUseCase = new StoreLocatorClickedUseCase(
            payload.storeId,
            payload.storeName,
            events.BOOK_APPOINTMENT_CLICKED
        );
        bookAppointmentClickedUseCase.execute();
    };

    @on(events.BOOK_VIRTUAL_APPOINTMENT_CLICKED)
    public BookVirtualAppointmentClicked = (payload: { isVirtual: boolean }) => {
        const bookVirtualAppointmentClickedUseCase = new StoreBookVirtualAppointmentClickedUseCase(
            payload.isVirtual,
            events.BOOK_VIRTUAL_APPOINTMENT_CLICKED
        );
        bookVirtualAppointmentClickedUseCase.execute();
    };

    @on(events.STORE_LOCATION_SELECTED)
    public StoreLocationSelected = (payload: { storeId: string }) => {
        const storeLocationSelectedUseCase = new StoreLocationSelectedUseCase(payload.storeId);
        storeLocationSelectedUseCase.execute();
    };

    @on(events.STORE_TOWN_SELECTED)
    public StoreTownSelected = (payload: { town: string }) => {
        const storeTownSelectedUseCase = new StoreTownSelectedUseCase(payload.town);
        storeTownSelectedUseCase.execute();
    };

    @on(events.SEARCH_ADDRESS_SUBMITTED)
    public SearchAddressSubmitted = (payload: { address: string }) => {
        const searchAddressSubmittedUseCase = new SearchAddressSubmittedUseCase(payload.address);
        searchAddressSubmittedUseCase.execute();
    };

    @on(events.PAYMENT_SUBMITTING)
    public trackOrderDetails = (orderQueryData: IOrderQueryData) => {
        this.orderQueryData = orderQueryData;
    };

    @on(events.CREATE_ACCOUNT_SUCCESS)
    public createAccountSuccess = async (payload: {}) => {
        const createAccountSuccessUseCase = new CreateAccountSuccessUseCase();
        createAccountSuccessUseCase.execute();
    };

    @on(events.CREATE_ACCOUNT_ATTEMPT)
    public createAccountAttempt = (payload: {}) => {
        const createAccountAttemptUseCase = new CreateAccountAttemptUseCase();
        createAccountAttemptUseCase.execute();
    };

    @on(events.REMOVE_ITEM)
    public removeItem = async (payload: { skuId: string; quantity: number }) => {
        const removeItemUseCase = new RemoveItemUseCase(payload.skuId);
        removeItemUseCase.execute();
    };

    @on(events.CREATE_ACCOUNT_FAIL)
    public createAccountFail = (error: string) => {
        const createAccountFailUseCase = new CreateAccountFailUseCase(error);
        createAccountFailUseCase.execute();
    };

    @on(events.AUTH_FAILED)
    public authFailed = async (error: string) => {
        const authFailedUseCase = new AuthFailedUseCase(error);
        authFailedUseCase.execute();
    };

    @on(events.AUTH_ATTEMPT)
    public authAttempt = async (payload: {}) => {
        const authAttemptUseCase = new AuthAttemptUseCase();
        authAttemptUseCase.execute();
    };

    @on(events.MANUAL_OFFER_CODE_APPLIED)
    public applyOfferCode = async () => {
        const manualOfferCodeAppliedUseCase = new ManualOfferCodeAppliedUseCase();
        manualOfferCodeAppliedUseCase.execute();
    };

    @on(events.OFFER_CODE_FAILED)
    public offerCodeFailed = async () => {
        const manualOfferCodeAppliedUseCase = new ManualOfferCodeAppliedUseCase();
        manualOfferCodeAppliedUseCase.execute();
    };

    @on(events.PRODUCT_CLICKED)
    public productClicked = async (payload: {
        skuId: string;
        pageType: string;
        positionIndex: number;
    }) => {
        const { skuId, positionIndex } = payload;
        const pageType = '';
        const productClickedUseCase = new ProductClickedUseCase(skuId, pageType, positionIndex);
        productClickedUseCase.execute();
    };

    @on(events.PRODUCT_NOTIFY_ME_TRIGGERED)
    public productNotifyMeTriggered = async (payload: { skuId: string }) => {
        const productNotifyMeTriggeredUseCase = new ProductNotifyMeTriggeredUseCase(payload.skuId);
        productNotifyMeTriggeredUseCase.execute();
    };

    @on(events.PRODUCT_NOTIFY_ME_REQUEST_SUCCESS)
    public productNotifyMeRequestSuccess = async (payload: { skuId: string }) => {
        const eventAction = 'notify me - success';

        const productNotifyMeRequestCompletedUseCase = new ProductNotifyMeRequestCompletedUseCase(
            payload.skuId,
            events.PRODUCT_NOTIFY_ME_REQUEST_SUCCESS,
            eventAction
        );
        productNotifyMeRequestCompletedUseCase.execute();
    };

    @on(events.PRODUCT_NOTIFY_ME_REQUEST_FAILURE)
    public productNotifyMeRequestFailure = async (payload: { skuId: string }) => {
        const eventAction = 'notify me - fail';

        const productNotifyMeRequestCompletedUseCase = new ProductNotifyMeRequestCompletedUseCase(
            payload.skuId,
            events.PRODUCT_NOTIFY_ME_REQUEST_FAILURE,
            eventAction
        );
        productNotifyMeRequestCompletedUseCase.execute();
    };

    @on(events.ITEM_ADDED)
    public itemAdded = async (payload: { skuId: string }) => {
        const itemAddedUseCase = new ItemAddedUseCase(payload.skuId);
        itemAddedUseCase.execute();
    };

    @on(events.SAMPLE_ADDED)
    public sampleAdded = async () => {
        const sampleAddedUseCase = new SampleAddedUseCase();
        sampleAddedUseCase.execute();
    };

    @on(events.SHIPPING_OPTION_SELECTED)
    public shippingOptionSelected = (payload: {}) => {
        const shippingOptionSelectedUseCase = new ShippingOptionSelectedUseCase();
        shippingOptionSelectedUseCase.execute();
    };

    @on(events.CART_LOGGED_IN)
    public cartLoggedIn = (payload: {}) => {
        const cartLoggedInUseCase = new CartLoggedInUseCase();
        cartLoggedInUseCase.execute();
    };

    @on(events.LOGGED_IN_SUCCESSFUL)
    public loggedInSuccessful = async (payload: {}) => {
        const loggedInSuccessfulUseCase = new LoggedInSuccessfulUseCase();
        loggedInSuccessfulUseCase.execute();
    };

    @on(events.ADDRESS_SELECTION_CHANGED)
    public shippingAddressFilled = ({ context }: { context: string }) => {
        if (context === SHIPPING_SCREEN_SHIPPING_ADDRESS_SELECTION_CONTEXT) {
            const shippingAddressFilledUseCase = new ShippingAddressFilledUseCase();
            shippingAddressFilledUseCase.execute();
        }
    };

    @on(events.ORDER_CREATED)
    public paymentMethodCompleted = (payload: {}) => {
        const paymentMethodCompletedUseCase = new PaymentMethodCompletedUseCase();
        paymentMethodCompletedUseCase.execute();
    };

    @on(events.ORDER_CONFIRMATION_VIEW)
    public orderConfirmationPageLanded = async (payload: IOrderDetail) => {
        const orderConfirmationPageLandedUseCase = new OrderConfirmationPageLandedUseCase(payload);
        orderConfirmationPageLandedUseCase.execute();
    };

    @on(events.SUBSCRIBED_FOR_EMAILS)
    public subscribedForEvents = () => {
        const subscribedForEventsUseCase = new SubscribedForEmailsUseCase();
        subscribedForEventsUseCase.execute();
    };

    @on(events.OUTBOUND_CLICKED)
    public outboundClicked = (url: string) => {
        const outboundClickedUseCase = new OutboundClickedUseCase(url);
        outboundClickedUseCase.execute();
    };

    @on(events.CART_PAGE_VIEW)
    public cartPageView = async (payload: {}) => {
        const cartLandingPageViewedUseCase = new CartLandingPageViewedUseCase();
        cartLandingPageViewedUseCase.execute();
    };

    @on(events.TRANSACTION_FAILED)
    public transactionFailed = () => {
        const transactionFailedUseCase = new TransactionFailedUseCase(this.orderQueryData);
        transactionFailedUseCase.execute();
    };

    @on(events.TRANSACTION_SUCCEDED)
    public transactionSucceded = () => {
        const transactionSuccededUseCase = new TransactionSuccededUseCase(this.orderQueryData);
        transactionSuccededUseCase.execute();
    };

    @on(events.MPP_FILTERED)
    public filterMpp = async (payload: { method: string }) => {
        const mppFilteredUseCase = new MppFilteredUseCase(payload.method);
        mppFilteredUseCase.execute();
    };

    @on(events.GRID_FILTERED)
    public filterGrid = async (payload: { appliedFilters: IAnalyticsFilters[] }) => {
        const gridFilteredUseCase = new GridFilteredUseCase(payload.appliedFilters);
        gridFilteredUseCase.execute();
    };

    @on(events.GRID_SORTED)
    public sortGrid = async (payload: { appliedSort: string }) => {
        const sortFilteredUseCase = new GridSortedUseCase(payload.appliedSort);
        sortFilteredUseCase.execute();
    };

    @on(events.QUICK_VIEW_TRIGGERED)
    public quickViewSelected = (payload: { skuId: string; positionIndex: number }) => {
        const pageType = QUICK_VIEW;
        const { skuId, positionIndex } = payload;
        const quickViewSelectedUseCase = new QuickViewUseCase(skuId);
        const productClickedUseCase = new ProductClickedUseCase(skuId, pageType, positionIndex);
        quickViewSelectedUseCase.execute();
        productClickedUseCase.execute();
    };

    @on(events.CART_OVERLAY_CLOSED)
    public cartOverlayClosed = async () => new CartOverlayClosed().execute();

    @on(events.CART_OVERLAY_OPENED)
    public cartOverlayOpened = async () => new CartOverlayOpened().execute();

    @on(events.CART_OVERLAY_TAB_CLICKED)
    public cartTabClicked = async ({ tabId }: { tabId: string }) => {
        const payload = { tabId, event: events.CART_OVERLAY_TAB_CLICKED };
        new CartTabTabClicked(payload).execute();
    };

    @on(events.CART_OVERLAY_OFFER_CLICKED)
    public cartOverlayOfferClicked = async (payload: IOfferClickedProps) => {
        new OfferClicked(payload).execute();
    };

    @on(events.CART_OVERLAY_OFFER_VIEWED)
    public cartOverlayOfferViewed = async (payload: IOfferViewedProps) => {
        new OfferViewed(payload).execute();
    };

    @on(events.PRODUCT_SHADE_GROUP_SELECTED)
    public shadeGroupSelected = (payload: {
        shadeGroupKey: string;
        eventName: string;
        eventAction: string;
    }) => {
        const { shadeGroupKey, eventName, eventAction } = payload;
        const shadeGroupSelectedUseCase = new ShadeGroupSelectedUseCase(
            shadeGroupKey,
            eventName,
            eventAction
        );
        shadeGroupSelectedUseCase.execute();
    };

    @on(events.PRODUCT_COMPARISON_VIEWED)
    public productComparisonViewed = (payload: { productIds: string[] }) => {
        const { productIds } = payload;
        const productComparisonViewedUseCase = new ProductComparisonViewedUseCase(productIds);
        productComparisonViewedUseCase.execute();
    };

    @on(events.SEARCH_PRODUCTS_QUERIED)
    public searchProductsQueried = (payload: ISearchProductsQueried) => {
        const searchProductsQueriedUseCase = new SearchProductsQueriedUseCase(payload);
        searchProductsQueriedUseCase.execute();
    };

    @on(events.SEARCH_TERM_SELECTED)
    public searchTermSelected = (payload: ISearchTermSelected) => {
        const searchTermSelectedUseCase = new SearchTermSelectedUseCase(payload);
        searchTermSelectedUseCase.execute();
    };

    @on(events.NAVIGATE)
    public navigatedToPageView = async ({ currentRoute }: { currentRoute: string }) => {
        switch (currentRoute) {
            case routes.ACCOUNT_LANDING:
            case routes.LOGIN:
                const accountPageLandedUseCase = new AccountPageLandedUseCase();
                accountPageLandedUseCase.execute();
                break;

            case routes.SHIPPING:
                const shippingPageViewedUseCase = new ShippingPageViewedUseCase();
                shippingPageViewedUseCase.execute();
                break;

            case routes.PAYMENT:
                const paymentPageViewedUseCase = new PaymentPageViewedUseCase();
                paymentPageViewedUseCase.execute();
                break;

            default:
                return;
        }
    };

    @on(events.VTO_CAMERA_CLOSED)
    public vtoCameraClosed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.CAMERA_CLOSED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoCameraClosedUseCase = new VTOActionUseCase(vtoAction);

        vtoCameraClosedUseCase.execute();
    };

    @on(events.VTO_CAMERA_FAILED)
    public vtoCameraFailed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.CAMERA_FAILED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoCameraFailedUseCase = new VTOActionUseCase(vtoAction);

        vtoCameraFailedUseCase.execute();
    };

    @on(events.VTO_CAMERA_OPENED)
    public vtoCameraOpened = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.CAMERA_OPENED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoCameraOpenedUseCase = new VTOActionUseCase(vtoAction);

        vtoCameraOpenedUseCase.execute();
    };

    @on(events.VTO_COMPARE)
    public vtoCompare = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.COMPARE,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoCompareUseCase = new VTOActionUseCase(vtoAction);

        vtoCompareUseCase.execute();
    };

    @on(events.VTO_COMPARE_DISABLED)
    public vtoCompareDisabled = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.COMPARE_DISABLED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoCompareDisabledUseCase = new VTOActionUseCase(vtoAction);

        vtoCompareDisabledUseCase.execute();
    };

    @on(events.VTO_CLOSED)
    public vtoClosed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.CLOSED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoClosedUseCase = new VTOActionUseCase(vtoAction);

        vtoClosedUseCase.execute();
    };

    @on(events.VTO_ENGINE_CLOSED)
    public vtoEngineClosed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.ENGINE_CLOSED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoEngineClosedUseCase = new VTOActionUseCase(vtoAction);

        vtoEngineClosedUseCase.execute();
    };

    @on(events.VTO_FOUNDATION_QR_CODE_OVERLAY_CLOSED)
    public vtoFoundationQrCodeOverlayClosed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.FOUNDATION_QR_CODE_OVERLAY_CLOSED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoFoundationQrCodeOverlayClosedUseCase = new VTOActionUseCase(vtoAction);

        vtoFoundationQrCodeOverlayClosedUseCase.execute();
    };

    @on(events.VTO_FOUNDATION_QR_CODE_OVERLAY_OPENED)
    public vtoFoundationQrCodeOverlayOpened = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.FOUNDATION_QR_CODE_OVERLAY_OPENED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoFoundationQrCodeOverlayOpenedUseCase = new VTOActionUseCase(vtoAction);

        vtoFoundationQrCodeOverlayOpenedUseCase.execute();
    };

    @on(events.VTO_LOADED)
    public vtoLoaded = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.LOADED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoLoadedUseCase = new VTOActionUseCase(vtoAction);

        vtoLoadedUseCase.execute();
    };

    @on(events.VTO_LOADING)
    public vtoLoading = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.LOADING,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoLoadingUseCase = new VTOActionUseCase(vtoAction);

        vtoLoadingUseCase.execute();
    };

    @on(events.VTO_KISS_DETECTED)
    public vtoKissDetected = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.KISS_DETECTED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoKissDetectedUseCase = new VTOActionUseCase(vtoAction);

        vtoKissDetectedUseCase.execute();
    };

    @on(events.VTO_OPENED)
    public vtoOpened = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.OPENED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoOpenedUseCase = new VTOActionUseCase(vtoAction);

        vtoOpenedUseCase.execute();
    };

    @on(events.VTO_PAIR_SHADE_GRID_OPENED)
    public vtoPairShadeGridOpened = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.PAIR_SHADE_GRID_OPENED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoOpenedUseCase = new VTOActionUseCase(vtoAction);

        vtoOpenedUseCase.execute();
    };

    @on(events.VTO_PAIR_SHADE_SELECTED)
    public vtoPairShadeSelected = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.PAIR_SHADE_SELECTED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoOpenedUseCase = new VTOActionUseCase(vtoAction);

        vtoOpenedUseCase.execute();
    };

    @on(events.VTO_PHOTO_LOADED)
    public vtoPhotoLoaded = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.PHOTO_LOADED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoPhotoLoadedUseCase = new VTOActionUseCase(vtoAction);

        vtoPhotoLoadedUseCase.execute();
    };

    @on(events.VTO_PHOTO_SAVED)
    public vtoPhotoSaved = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.PHOTO_SAVED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoPhotoSavedUseCase = new VTOActionUseCase(vtoAction);

        vtoPhotoSavedUseCase.execute();
    };

    @on(events.VTO_PRICE_SUPPRESSED)
    public vtoPriceSuppressed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.PRICE_SUPPRESSED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoPriceSuppressedUsedCase = new VTOActionUseCase(vtoAction);

        vtoPriceSuppressedUsedCase.execute();
    };

    @on(events.VTO_RESET)
    public vtoReset = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.RESET,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoResetUseCase = new VTOActionUseCase(vtoAction);

        vtoResetUseCase.execute();
    };

    @on(events.VTO_VIDEO_LOADED)
    public vtoVideoLoaded = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.VIDEO_LOADED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoVideoLoadedUseCase = new VTOActionUseCase(vtoAction);

        vtoVideoLoadedUseCase.execute();
    };

    @on(events.VTO_ALL_SHADES_VIEWED)
    public vtoViewAllShades = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.ALL_SHADES_VIEWED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoViewAllShadesUseCase = new VTOActionUseCase(vtoAction);

        vtoViewAllShadesUseCase.execute();
    };

    @on(events.VTO_BEST_SHADES_VIEWED)
    public vtoViewBestShades = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.BEST_SHADES_VIEWED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoViewBestShadesUseCase = new VTOActionUseCase(vtoAction);

        vtoViewBestShadesUseCase.execute();
    };

    @on(events.VTO_SHADE_FINDER_STARTED)
    public vtoShadeFinderStarted = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.SHADE_FINDER_STARTED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoShadeFinderStartedUseCase = new VTOActionUseCase(vtoAction);

        vtoShadeFinderStartedUseCase.execute();
    };

    @on(events.VTO_SHADE_FINDER_COMPLETED)
    public vtoShadeFinderCompleted = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.SHADE_FINDER_COMPLETED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoShadeFinderCompletedUseCase = new VTOActionUseCase(vtoAction);

        vtoShadeFinderCompletedUseCase.execute();
    };

    @on(events.VTO_SHADE_FINDER_CANCELLED)
    public vtoShadeFinderCancelled = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.SHADE_FINDER_CANCELLED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoShadeFinderCancelledUseCase = new VTOActionUseCase(vtoAction);

        vtoShadeFinderCancelledUseCase.execute();
    };

    @on(events.VTO_SKIN_SHADE_FOUND)
    public vtoSkinShadeFound = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.SKIN_SHADE_FOUND,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoSkinShadeFoundUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinShadeFoundUseCase.execute();
    };

    @on(events.VTO_ZOOM)
    public vtoZoom = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.ZOOM,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoZoomUseCase = new VTOActionUseCase(vtoAction);

        vtoZoomUseCase.execute();
    };

    @on(events.VTO_MATCH_SAVED)
    public vtoMatchSaved = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.MATCH_SAVED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoMatchSavedUseCase = new VTOActionUseCase(vtoAction);

        vtoMatchSavedUseCase.execute();
    };

    @on(events.VTO_MATCH_REMOVED)
    public vtoMatchRemoved = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.MATCH_REMOVED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoMatchRemovedUseCase = new VTOActionUseCase(vtoAction);

        vtoMatchRemovedUseCase.execute();
    };

    @on(events.VTO_MATCH_PERSONALIZED)
    public vtoMatchPersonalized = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.MATCH_PERSONALIZED,
            pageType: YOUCAM,
            vtoType: payload.vtoType
        };
        const vtoMatchPersonalizedUseCase = new VTOActionUseCase(vtoAction);

        vtoMatchPersonalizedUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_OPENED)
    public vtoSkinCareOpened = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.OPENED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareOpenedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareOpenedUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_CAMERA_OPENED)
    public vtoSkinCareCameraOpened = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.CAMERA_OPENED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareCameraOpenedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareCameraOpenedUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_CAMERA_FAILED)
    public vtoSkinCareCameraFailed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.CAMERA_FAILED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareCameraFailedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareCameraFailedUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_CAMERA_CLOSED)
    public vtoSkinCareCameraClosed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.CAMERA_CLOSED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareCameraClosedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareCameraClosedUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_LOADING)
    public vtoSkinCareLoading = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.LOADING,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareLoadingUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareLoadingUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_LOADED)
    public vtoSkinCareLoaded = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.LOADED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareLoadedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareLoadedUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_VIDEO_LOADED)
    public vtoSkinCareVideoLoaded = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.VIDEO_LOADED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareVideoLoadedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareVideoLoadedUseCase.execute();
    };

    @on(events.VTO_SKIN_ANALYSIS_STARTED)
    public vtoSkinCareAnalysisStarted = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.SKIN_ANALYSIS_STARTED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareAnalysisStartedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareAnalysisStartedUseCase.execute();
    };

    @on(events.VTO_SKIN_ANALYSIS_FINISHED)
    public vtoSkinCareAnalysisFinished = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.SKIN_ANALYSIS_FINISHED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareAnalysisFinishedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareAnalysisFinishedUseCase.execute();
    };

    @on(events.VTO_SKIN_BACK_TO_DETECTING)
    public vtoSkinCareBackToDetecting = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.SKIN_BACK_TO_DETECTING,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareBackToDetectingUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareBackToDetectingUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_CLOSED)
    public vtoSkinCareClosed = (payload: IVtoType) => {
        const vtoAction = {
            event: VTO_EVENTS.CLOSED,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareClosedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareClosedUseCase.execute();
    };

    @on(events.VTO_SKIN_CARE_PRODUCTS_LOADED)
    public vtoSkinCareProductsLoaded = (payload: ISkinCareProducts) => {
        const { SHOPPABLE_PRODUCTS_LOADED, NON_SHOPPABLE_PRODUCTS_LOADED } = VTO_EVENTS;
        const event = payload.isShoppable
            ? SHOPPABLE_PRODUCTS_LOADED
            : NON_SHOPPABLE_PRODUCTS_LOADED;
        const vtoAction = {
            event,
            pageType: YOUCAM_SKINCARE,
            vtoType: payload.vtoType
        };
        const vtoSkinCareProductsLoadedUseCase = new VTOActionUseCase(vtoAction);

        vtoSkinCareProductsLoadedUseCase.execute();
    };

    @on(events.SKU_ENGRAVING_TRIGGERED)
    public engravingTriggered = (payload: {
        skuId: string;
        skuBaseId?: number;
        size: string;
        price: string;
        name: string;
    }) => {
        const skuEngravingUseCase = new SkuEngravingUseCase(payload);
        skuEngravingUseCase.execute();
    };

    @on(events.WRITE_REVIEWS_LINK_CLICKED)
    public writeReviewEventTriggered = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.WRITE_REVIEWS_LINK_CLICKED, {
            ...payload,
            eventAction: ReviewsEventAction.WRITE_REVIEW
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.READ_REVIEWS_LINK_CLICKED)
    public readReviewsEventTriggered = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.WRITE_REVIEWS_LINK_CLICKED, {
            ...payload,
            eventAction: ReviewsEventAction.READ_REVIEW
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.REVIEWS_HELPFUL_YES_CLICKED)
    public reviewsHelpfulYesClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(
            events.REVIEWS_HELPFUL_YES_CLICKED,
            {
                ...payload,
                eventAction: ReviewsEventAction.HELPFUL_YES
            }
        );
        ratingsReviewsUseCase.execute();
    };

    @on(events.REVIEWS_HELPFUL_NO_CLICKED)
    public reviewsHelpfulNoClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.REVIEWS_HELPFUL_NO_CLICKED, {
            ...payload,
            eventAction: ReviewsEventAction.HELPFUL_NO
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.REVIEWS_FLAG_REVIEW_CLICKED)
    public reviewsFlagReviewClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(
            events.REVIEWS_FLAG_REVIEW_CLICKED,
            {
                ...payload,
                eventAction: ReviewsEventAction.FLAG_REVIEW
            }
        );
        ratingsReviewsUseCase.execute();
    };

    @on(events.REVIEWS_FILTER_CLICKED)
    public reviewsFilterClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.REVIEWS_FILTER_CLICKED, {
            ...payload,
            eventAction: ReviewsEventAction.REVIEWS_FILTER
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.REVIEWS_SEARCH_CLICKED)
    public reviewsSearchClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.REVIEWS_SEARCH_CLICKED, {
            ...payload,
            eventAction: ReviewsEventAction.REVIEWS_SEARCH
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.ASK_AND_ANSWER_CLICKED)
    public askAndAnswerClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.ASK_AND_ANSWER_CLICKED, {
            ...payload,
            eventAction: ReviewsEventAction.ASK_AND_ANSWER
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.ASK_AND_ANSWER_SUBMITTED)
    public askAndAnswerSubmitted = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.ASK_AND_ANSWER_SUBMITTED, {
            ...payload,
            eventAction: ReviewsEventAction.ASK_AND_ANSWER_SUBMIT
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.ASK_AND_ANSWER_SEARCH_CLICKED)
    public askAndAnswerSearchClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(
            events.ASK_AND_ANSWER_SEARCH_CLICKED,
            {
                ...payload,
                eventAction: ReviewsEventAction.ASK_AND_ANSWER_SEARCH
            }
        );
        ratingsReviewsUseCase.execute();
    };

    @on(events.ASK_AND_ANSWER_HELPFUL_YES_CLICKED)
    public askAndAnswerHelpfulYesClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(
            events.ASK_AND_ANSWER_HELPFUL_YES_CLICKED,
            {
                ...payload,
                eventAction: ReviewsEventAction.ASK_AND_ANSWER_HELPFUL_YES
            }
        );
        ratingsReviewsUseCase.execute();
    };

    @on(events.ASK_AND_ANSWER_HELPFUL_NO_CLICKED)
    public askAndAnswerHelpfulNoClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(
            events.ASK_AND_ANSWER_HELPFUL_NO_CLICKED,
            {
                ...payload,
                eventAction: ReviewsEventAction.ASK_AND_ANSWER_HELPFUL_NO
            }
        );
        ratingsReviewsUseCase.execute();
    };

    @on(events.ASK_AND_ANSWER_SHOW_MORE_CLICKED)
    public askAndAnswerShowMoreClicked = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(
            events.ASK_AND_ANSWER_SHOW_MORE_CLICKED,
            {
                ...payload,
                eventAction: ReviewsEventAction.ASK_AND_ANSWER_SHOW_MORE
            }
        );
        ratingsReviewsUseCase.execute();
    };

    @on(events.REVIEWS_SORT_BY_CHANGED)
    public reviewsSortByChanged = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.REVIEWS_SORT_BY_CHANGED, {
            ...payload,
            eventAction: ReviewsEventAction.REVIEW_SORT
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.ASK_AND_ANSWERS_SORT_BY_CHANGED)
    public askAndAnswerSortByChanged = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(
            events.ASK_AND_ANSWERS_SORT_BY_CHANGED,
            {
                ...payload,
                eventAction: ReviewsEventAction.ASK_AND_ANSWER_SORT
            }
        );
        ratingsReviewsUseCase.execute();
    };

    @on(events.WRITE_REVIEW_STARTED)
    public writeReviewStarted = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.WRITE_REVIEW_STARTED, {
            ...payload,
            eventAction: ReviewsEventAction.WRITE_REVIEW
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.REVIEW_OVERLAY_OPENED)
    public reviewOverlayOpened = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.REVIEW_OVERLAY_OPENED, {
            ...payload,
            eventAction: ReviewsEventAction.REVIEW_OVERLAY_OPEN
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.REVIEW_OVERLAY_CLOSED)
    public reviewOverlayClosed = (payload: IReviewsData) => {
        const ratingsReviewsUseCase = new RatingsReviewsUseCase(events.REVIEW_OVERLAY_CLOSED, {
            ...payload,
            eventAction: ReviewsEventAction.REVIEW_OVERLAY_CLOSE
        });
        ratingsReviewsUseCase.execute();
    };

    @on(events.SMARTGIFT_ADDED)
    public smartGiftAdded = (payload: ISmartGiftData) => {
        const smartGiftUseCase = new SmartGiftUseCase(events.SMARTGIFT_ADDED, {
            ...payload,
            eventAction: SmartGiftEventAction.SMARTGIFT_ADDED
        });
        smartGiftUseCase.execute();
    };

    @on(events.SMARTGIFT_VIEWED)
    public smartGiftViewed = (payload: ISmartGiftData) => {
        const smartGiftUseCase = new SmartGiftUseCase(events.SMARTGIFT_VIEWED, {
            ...payload,
            eventAction: SmartGiftEventAction.SMARTGIFT_VIEWED
        });
        smartGiftUseCase.execute();
    };

    @on(events.SMARTGIFT_PREPARED)
    public smartGiftPrepared = (payload: ISmartGiftData) => {
        const smartGiftUseCase = new SmartGiftUseCase(events.SMARTGIFT_PREPARED, {
            ...payload,
            eventAction: SmartGiftEventAction.SMARTGIFT_PREPARED
        });
        smartGiftUseCase.execute();
    };

    @on(events.SMARTGIFT_WIDGET_TOGGLED)
    public smartGiftWidgetToggled = (payload: ISmartGiftData) => {
        const smartGiftUseCase = new SmartGiftUseCase(events.SMARTGIFT_WIDGET_TOGGLED, {
            ...payload,
            eventAction: SmartGiftEventAction.SMARTGIFT_WIDGET_TOGGLED
        });
        smartGiftUseCase.execute();
    };

    @on(events.SMARTGIFT_REMOVED)
    public smartGiftRemoved = (payload: ISmartGiftData) => {
        const smartGiftUseCase = new SmartGiftUseCase(events.SMARTGIFT_REMOVED, {
            ...payload,
            eventAction: SmartGiftEventAction.SMARTGIFT_REMOVED
        });
        smartGiftUseCase.execute();
    };

    @on(events.SMARTGIFT_CANCELLED)
    public smartGiftCancelled = (payload: ISmartGiftData) => {
        const smartGiftUseCase = new SmartGiftUseCase(events.SMARTGIFT_CANCELLED, {
            ...payload,
            eventAction: SmartGiftEventAction.SMARTGIFT_CANCELLED
        });
        smartGiftUseCase.execute();
    };

    @on(events.SMARTGIFT_HELP_OPENED)
    public smartgiftHelpOpen = (payload: ISmartGiftData) => {
        const smartGiftUseCase = new SmartGiftUseCase(events.SMARTGIFT_HELP_OPENED, {
            ...payload,
            eventAction: SmartGiftEventAction.SMARTGIFT_HELP_OPENED
        });
        smartGiftUseCase.execute();
    };

    @on(events.SMARTGIFT_HELP_CLOSED)
    public smartgiftHelpClosed = (payload: ISmartGiftData) => {
        const smartGiftUseCase = new SmartGiftUseCase(events.SMARTGIFT_HELP_CLOSED, {
            ...payload,
            eventAction: SmartGiftEventAction.SMARTGIFT_HELP_CLOSED
        });
        smartGiftUseCase.execute();
    };

    @on(events.ACCOUNT_SIDEBAR_MENU_LINK_CLICKED)
    public accountSidebarMenuClicked = (payload: IAccountSidebarBaseData) => {
        const accountSidebarUseCase = new AccountSidebarMenuOptionClickedUseCase({
            ...payload,
            eventAction: AccountEventAction.ACCOUNT_SIDEBAR_MENU_CLICKED
        });
        accountSidebarUseCase.execute();
    };

    @on(events.ACCOUNT_SIDEBAR_LOYALTY_LINK_CLICKED)
    public accountSidebarLoyaltyClicked = (payload: IAccountSidebarBaseData) => {
        const accountSidebarUseCase = new AccountSidebarMenuOptionClickedUseCase({
            ...payload,
            eventAction: AccountEventAction.ACCOUNT_SIDEBAR_LOYALTY_CLICKED
        });
        accountSidebarUseCase.execute();
    };

    @on(events.ACCOUNT_SIDEBAR_SHOWCASE_LINK_CLICKED)
    public accountSidebarShowcaseClicked = (payload: IAccountSidebarShowcaseData) => {
        const accountSidebarUseCase = new AccountSidebarShowcaseClickedUseCase({
            ...payload,
            eventAction: AccountEventAction.ACCOUNT_SIDEBAR_SHOWCASE_CLICKED
        });
        accountSidebarUseCase.execute();
    };

    @on(events.LIVE_CHAT_INTERFACE_CLICKED)
    public liveChatInterfaceClicked = (payload: IGenericAnalyticsData) => {
        const liveChatInterfaceClicked = new LiveChatInterfaceClickedUseCase(payload);
        liveChatInterfaceClicked.execute();
    };

    @on(events.SOCIAL_SHARE_ICON_TRIGGERED)
    public socialShareIconTriggered = (payload: IGenericAnalyticsData) => {
        const socialShareIconTriggered = new SocialShareIconTriggeredUseCase(payload);
        socialShareIconTriggered.execute();
    };

    @on(events.SKU_PICKER_OPENED)
    public skuPickerOpened = (payload: { productId: string }) => {
        const { productId } = payload;
        const skuPickerOpened = new SkuPickerInteractionUseCase(productId, ActionType.OPEN);
        skuPickerOpened.execute();
    };

    @on(events.SKU_PICKER_CLOSED)
    public skuPickerClosed = (payload: { productId: string }) => {
        const { productId } = payload;
        const skuPickerClosed = new SkuPickerInteractionUseCase(productId, ActionType.CLOSE);
        skuPickerClosed.execute();
    };

    @on(events.APPOINTMENT_VIRTUAL_SELECTED)
    public appointmentVirtualSelected = () => {
        const appointmentVirtualSelected = new AppointmentVirtualSelectedUseCase();
        void appointmentVirtualSelected.execute();
    };

    @on(events.APPOINTMENT_LOCATION_SELECTED)
    public appointmentLocationSelected = (payload: { storeName: string }) => {
        const { storeName } = payload;
        const appointmentLocationSelected = new AppointmentLocationSelectedUseCase(storeName);
        void appointmentLocationSelected.execute();
    };

    @on(events.APPOINTMENT_SERVICE_SELECTED)
    public appointmentServiceSelected = (payload: { serviceName: string[] }) => {
        const { serviceName } = payload;
        const appointmentServiceSelected = new AppointmentServiceSelectedUseCase(serviceName);
        void appointmentServiceSelected.execute();
    };

    @on(events.APPOINTMENT_DATE_TIME_SELECTED)
    public appointmentDateTimeSelected = (payload: { dateTime: string }) => {
        const { dateTime } = payload;
        const appointmentDateTimeSelected = new AppointmentDateTimeSelectedUseCase(dateTime);
        void appointmentDateTimeSelected.execute();
    };

    @on(events.APPOINTMENT_EDIT_CLICKED)
    public appointmentEditClicked = () => {
        const appointmentEditClicked = new AppointmentEditClickedUseCase();
        void appointmentEditClicked.execute();
    };

    @on(events.APPOINTMENT_STARTED_OVER)
    public appointmentStartedOver = () => {
        const appointmentStartedOver = new AppointmentStartedOverUseCase();
        void appointmentStartedOver.execute();
    };

    @on(events.APPOINTMENT_ARTIST_SELECTED)
    public appointmentArtistSelected = (payload: { artistName: string }) => {
        const { artistName } = payload;
        const appointmentArtistSelected = new AppointmentArtistSelectedUseCase(artistName);
        void appointmentArtistSelected.execute();
    };

    @on(events.APPOINTMENT_STEP_LOADED)
    public appointmentStepLoaded = (payload: { step: string }) => {
        const { step } = payload;
        const appointmentStepLoaded = new AppointmentStepLoadedUseCase(step);
        void appointmentStepLoaded.execute();
    };

    @on(events.APOOINTMENT_PROCESS_COMPLETED)
    public appointmentProcessCompleted = () => {
        const appointmentProcessCompleted = new AppointmentProcessCompletedUseCase();
        void appointmentProcessCompleted.execute();
    };

    @on(events.APPOINTMENT_ADDED_TO_CALENDAR)
    public appointmentAddedToCalendar = () => {
        const appointmentAddedToCalendar = new AppointmentAddedToCalendarUseCase();
        void appointmentAddedToCalendar.execute();
    };
}
