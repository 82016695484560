import { queryProvider } from '@estee/elc-service-bus';

export class QueryProvidersMock {
    @queryProvider('isAuthenticated')
    public isAuthenticated = () => {
        return true;
    };

    @queryProvider('birth.date')
    public birthDate = () => {
        return '';
    };

    @queryProvider('get.config')
    public getConfig = () => {
        return 'EUR';
    };
}
