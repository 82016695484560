export enum VTO_EVENTS {
    ALL_SHADES_VIEWED = 'allShadesViewed',
    BEST_SHADES_VIEWED = 'bestShadesViewed',
    CAMERA_CLOSED = 'cameraClosed',
    CAMERA_FAILED = 'cameraFailed',
    CAMERA_OPENED = 'cameraOpened',
    CLOSED = 'closed',
    COMPARE = 'compare',
    COMPARE_DISABLED = 'compareDisabled',
    ENGINE_CLOSED = 'engineClosed',
    FOUNDATION_QR_CODE_OVERLAY_CLOSED = 'foundationQrCodeOverlayClosed',
    FOUNDATION_QR_CODE_OVERLAY_OPENED = 'foundationQrCodeOverlayOpened',
    KISS_DETECTED = 'kissDetected',
    LOADED = 'loaded',
    LOADING = 'loading',
    MATCH_PERSONALIZED = 'myMatchPersonalized',
    MATCH_REMOVED = 'myMatchRemoved',
    MATCH_SAVED = 'myMatchSaved',
    NON_SHOPPABLE_PRODUCTS_LOADED = 'nonShoppableProductsLoaded',
    OPENED = 'opened',
    PAIR_SHADE_GRID_OPENED = 'pairShadeGridOpened',
    PAIR_SHADE_SELECTED = 'pairShadeSelected',
    PHOTO_LOADED = 'photoLoaded',
    PHOTO_SAVED = 'photoSaved',
    PRICE_SUPPRESSED = 'priceSuppressed',
    RESET = 'reset',
    SHADE_FINDER_CANCELLED = 'shadeFinderCancelled',
    SHADE_FINDER_COMPLETED = 'shadeFinderCompleted',
    SHADE_FINDER_STARTED = 'shadeFinderStared',
    SHOPPABLE_PRODUCTS_LOADED = 'shoppableProductsLoaded',
    SKIN_ANALYSIS_FINISHED = 'skinAnalysisFinished',
    SKIN_ANALYSIS_STARTED = 'skinAnalysisStarted',
    SKIN_BACK_TO_DETECTING = 'skinBackToDetecting',
    SKIN_SHADE_FOUND = 'skinShadeFound',
    VIDEO_LOADED = 'videoLoaded',
    ZOOM = 'zoom'
}
export const YOUCAM = 'YouCam';
export const YOUCAM_SKINCARE = 'YouCamSkincare';
