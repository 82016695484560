import { EventRepository } from '../../internal/repositories/EventRepository';
import { events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import * as routes from '../../constants/Routes';

export class LoggedInSuccessfulUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const isCartPage = window.location.href.indexOf('cart') !== -1 ? true : false;
        // TODO: can we pass this in instead of querying for it?
        const routerStatus = await query(serviceQueries.GET_CURRENT_ROUTE);
        const currentRoute = routerStatus.currentRoute;
        const logInSource = isCartPage
            ? 'cart'
            : currentRoute === routes.ACCOUNT_LANDING
            ? 'account'
            : 'checkout';

        const loggedInSuccessfulPayload = {
            event_name: 'login_success',
            event_category: logInSource,
            event_action: 'login success',
            active_checkout_panel: logInSource,
            event_label: 'standard'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.LOGGED_IN_SUCCESSFUL,
            type: 'link',
            payload: loggedInSuccessfulPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
