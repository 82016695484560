import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { IGenericAnalyticsData } from '../interfaces/IAnalytics';

export class LiveChatInterfaceClickedUseCase {
    private eventRepository: EventRepository;
    private liveChatData: IGenericAnalyticsData;

    constructor(liveChatData: IGenericAnalyticsData) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.liveChatData = liveChatData;
    }

    public execute = () => {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.LIVE_CHAT_INTERFACE_CLICKED,
            type: 'link',
            payload: this.getLiveChatPayload()
        });

        this.eventRepository.addEventToQueue(newEvent);
    };

    private getLiveChatPayload() {
        const { eventLabel } = this.liveChatData;

        return {
            event_name: 'liveperson chat interface open',
            event_category: 'live chat interaction ',
            event_action: 'user initiated chat',
            event_label: eventLabel
        };
    }
}
