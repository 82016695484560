import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class ProductComparisonViewedUseCase {
    private eventRepository: EventRepository;
    private productIds: string[];

    constructor(productIds: string[]) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.productIds = productIds;
    }

    public execute = () => {
        const eventLabel = this.productIds.join('|');
        const payload = {
            event_name: 'comparison view',
            event_category: 'product comparison',
            event_action: 'comparison',
            event_label: eventLabel,
            event_noninteraction: 'true'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.PRODUCT_COMPARISON_VIEWED,
            type: 'view',
            payload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
