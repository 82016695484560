import { EventRepository } from '../repositories/EventRepository';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class StoreBookVirtualAppointmentClickedUseCase {
    private eventRepostiory: EventRepository;
    private isVirtual: boolean;
    private eventLabel: string;

    constructor(isVirtual: boolean, eventLabel: string) {
        this.eventRepostiory = diContainer.get(serviceNames.eventRepository);
        this.isVirtual = isVirtual;
        this.eventLabel = eventLabel;
    }

    public execute = async () => {
        const storeLocatorBookVirtualAppointmentClickedPayload = {
            event_name: this.eventLabel,
            event_action: this.eventLabel,
            event_label: this.isVirtual
        };

        const newEvent = new Event({
            id: this.eventRepostiory.generateId(),
            name: this.eventLabel,
            type: 'link',
            payload: storeLocatorBookVirtualAppointmentClickedPayload
        });

        this.eventRepostiory.addEventToQueue(newEvent);
    };
}
