import { EventRepository } from '../repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { ISmartGiftData } from '../interfaces/IAnalytics';

export class SmartGiftUseCase {
    private eventRepository: EventRepository;
    private eventName: string;
    private smartGiftData: ISmartGiftData;

    constructor(eventName: string, smartGiftData: ISmartGiftData) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.smartGiftData = smartGiftData;
        this.eventName = eventName;
    }

    public execute() {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: this.eventName,
            type: 'link',
            payload: this.getSmartGiftPayload()
        });

        this.eventRepository.addEventToQueue(newEvent);
    }

    private getSmartGiftPayload() {
        const { productId, eventAction } = this.smartGiftData;

        return {
            event_name: 'smartgift',
            event_category: 'smartgift',
            event_action: eventAction,
            event_label: `PROD${productId}`
        };
    }
}
