import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class AppointmentServiceSelectedUseCase {
    private eventRepository: EventRepository;
    private serviceName: string[];

    constructor(serviceName: string[]) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.serviceName = serviceName;
    }

    public execute = async () => {
        const appointmentServiceSelectedPayload = {
            event_name: 'appointment booking',
            event_category: 'appointment booking',
            event_action: 'service selected',
            event_label: this.serviceName
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.APPOINTMENT_SERVICE_SELECTED,
            type: 'link',
            payload: appointmentServiceSelectedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
