import { EventRepository } from '../../internal/repositories/EventRepository';
import * as views from '../../constants/Views';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class PaymentPageViewedUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = () => {
        const paymentPageViewedPayload = {
            active_checkout_panel: 'checkout'
        };

        // TODO: this is probably going to need to be a view type
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: views.PAYMENT_VIEW,
            type: 'data',
            payload: paymentPageViewedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
