import { EventRepository } from '../../internal/repositories/EventRepository';
import { events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class ManualOfferCodeAppliedUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        // TODO: can we pass this in instead of querying for it?
        const manualOffer = await query(serviceQueries.GET_MANUAL_OFFER);
        const offerStatus = manualOffer.message && Object.keys(manualOffer.message)[0];
        const isOfferFailed = offerStatus === 'error';

        const offerCodePayload = {
            event_name: isOfferFailed ? 'offers_fail' : 'offers_success',
            event_category: 'offers',
            event_action: isOfferFailed ? 'fail' : 'success',
            event_label: isOfferFailed
                ? `${manualOffer.offerCode} - ${manualOffer.message.error}`
                : `${manualOffer.offerCode}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: isOfferFailed ? events.OFFER_CODE_FAILED : events.OFFER_CODE_SUCCEDED,
            type: 'link',
            payload: offerCodePayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
