import { root, isWebRendered } from '@estee/elc-universal-utils';
import { emit, events } from '@estee/elc-service-bus';

interface IMouseEvent extends Event {
    target: HTMLAnchorElement;
}

export class OutboundLinkListener {
    private root: Window;

    constructor() {
        this.root = <Window>root;
    }

    public init = () => {
        if (isWebRendered) {
            this.root.addEventListener('click', (e: IMouseEvent) => {
                if (!e.target || !e.target.tagName || !e.target.href) {
                    return;
                }
                if (e.target.tagName === 'A') {
                    const currentDomain = (<Location>root.location).hostname;
                    const linkHref = e.target.href;
                    if (linkHref.indexOf(currentDomain) === -1) {
                        emit(events.OUTBOUND_CLICKED, linkHref);
                    }
                }
            });
        }
    };
}
