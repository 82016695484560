import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class AppointmentArtistSelectedUseCase {
    private eventRepository: EventRepository;
    private artistName: string;

    constructor(artistName: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.artistName = artistName;
    }

    public execute = async () => {
        const appointmentArtistSelectedPayload = {
            event_name: 'appointment booking',
            event_category: 'appointment booking',
            event_action: 'artist selected',
            event_label: this.artistName
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.APPOINTMENT_ARTIST_SELECTED,
            type: 'link',
            payload: appointmentArtistSelectedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
