import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class AppointmentStepLoadedUseCase {
    private eventRepository: EventRepository;
    private step: string;

    constructor(step: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.step = step;
    }

    public execute = async () => {
        const appointmentStepPayload = {
            page_url: `/book-appointment/step${this.step}`,
            page_name: `Book Appointment - Step ${this.step}`,
            location: `${location.origin}/book-appointment/step${this.step}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.APPOINTMENT_STEP_LOADED,
            type: 'view',
            payload: appointmentStepPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
