import { events } from '@estee/elc-service-bus';

import { EventRepository } from '../repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export interface IOfferClickedProps {
    placement: string;
    promotionId: string;
    promotionName: string;
}

export class OfferClicked {
    private eventRepository: EventRepository;
    private _promotionName: string;
    private _promotionId: string;

    constructor({ promotionId, promotionName }: IOfferClickedProps) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this._promotionName = promotionName;
        this._promotionId = promotionId;
    }

    public execute = () => {
        const offerCodeFailedPayload = {
            event_name: 'content_module_click',
            enh_action: 'promo_click',
            event_category: 'ecommerce',
            event_action: 'promotion click',
            event_label: this._promotionName ? [`${this._promotionId}`] : 'content module click',
            promo_pos: ['module'],
            promo_creative: ['Cart Overlay'],
            promo_name: [`${this._promotionName}`],
            promo_id: [`module-Cart Overlay-${this._promotionId}`]
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.CART_OVERLAY_OFFER_CLICKED,
            type: 'link',
            payload: offerCodeFailedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
