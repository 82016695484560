import { EventRepository } from '../repositories/EventRepository';
import { events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import * as routes from '../../constants/Routes';

export class AuthAttemptUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const isCartPage = window.location.href.indexOf('cart') !== -1 ? true : false;
        const routerStatus = await query(serviceQueries.GET_CURRENT_ROUTE);
        const currentRoute = isCartPage ? 'cart' : routerStatus.currentRoute;
        const authSource =
            currentRoute === routes.ACCOUNT_LANDING
                ? 'account'
                : currentRoute === routes.CART
                ? 'cart'
                : 'checkout';

        const authAttemptPayload = {
            event_name: 'login_attempt',
            event_category: authSource,
            event_action: 'login attempt',
            active_checkout_panel: authSource,
            event_label: 'standard'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.AUTH_ATTEMPT,
            type: 'link',
            payload: authAttemptPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
