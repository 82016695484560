import { EventRepository } from '../../internal/repositories/EventRepository';
import { events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { mapProductSkusById } from '../../utils/dataAggregator';
import { ISkuData } from './ProductNotifyMeTriggeredUseCase';

export class QuickViewUseCase {
    private eventRepository: EventRepository;
    private skuId: string;

    constructor(skuId: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.skuId = skuId;
    }

    public execute = async () => {
        // TODO: we shouldn't need to call this twice
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const data = <any>await query(serviceQueries.GET_PRODUCT_DATA, {
            filterBy: { skus: { skuIds: [this.skuId] } }
        });
        const skuIdMap: ISkuData = data.reduce(mapProductSkusById, {});

        const productInfo = skuIdMap[this.skuId];

        // TODO: figure out a way to deal with productInfo here so that it can be done in DataAggregator
        const quickViewPayload = {
            enh_action: 'detail',
            event_action: 'product detail view',
            event_category: 'ecommerce',
            event_label: `${productInfo.display_name} - PROD${productInfo.product_id}`,
            event_name: 'mpp_qv',
            event_noninteraction: 'FALSE',
            page_name: `QV | ${productInfo.display_name}`
        };

        const productShortPayload = {
            skuId: this.skuId
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.QUICK_VIEW_TRIGGERED,
            type: 'link',
            payload: quickViewPayload,
            data: {
                productShort: productShortPayload
            }
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
