import { EventRepository } from './repositories/EventRepository';
import { Event } from '../internal/domain/entities/Event';
import { query, serviceQueries } from '@estee/elc-service-bus';
import { $mobx, reaction } from 'mobx';
import { root } from '@estee/elc-universal-utils';
import { isTagId, mapProductSkusById } from '../utils/dataAggregator';

import {
    IProductImpressions,
    IProductViewed,
    IProduct,
    ICartProducts
} from './interfaces/IAnalytics';

export class DataAggregator {
    private eventRepository: EventRepository;

    constructor(eventRepository: EventRepository) {
        this.eventRepository = eventRepository;
        reaction(() => this.eventRepository.incompleteEvents, this.saturateIncompleteEvents);
    }

    public saturateIncompleteEvents = (incompleteEvents: Event[]) => {
        incompleteEvents.forEach((event) => {
            if (!event.isExecuting) {
                event.markEventAsExecuting();
                this.checkForMissingInformation(event);
            }
        });
    };

    public checkForMissingInformation = async (event: Event) => {
        const collectionsResults = await Promise.all(
            Object.keys(event.data).map((collection) => {
                if (collection === 'cartProduct' && event.data.cartProduct) {
                    return this.getCartProductInfo(event.data.cartProduct);
                } else if (collection === 'cartProducts' && event.data.cartProducts) {
                    return this.getCartProductsInfo(event.data.cartProducts);
                } else if (collection === 'product' && event.data.product) {
                    return this.getProductInfo(event.data.product);
                } else if (collection === 'productImpressions' && event.data.productImpressions) {
                    return this.getProductImpresionsInfo(event.data.productImpressions);
                } else if (collection === 'productShort' && event.data.productShort) {
                    return this.getShortProductInfo(event.data.productShort);
                }
            })
        );

        const collectionsInfo = collectionsResults.reduce(
            (collectionInfoPayload, currentCollection) => {
                return { ...collectionInfoPayload, ...currentCollection };
            },
            {}
        );

        this.eventRepository.markEventAsComplete(event.id, {
            ...collectionsInfo
        });
    };

    // TODO: determine an easier way to map product data to the data layer format - maybe see how this is done in search DataMappings
    // eslint-disable-next-line complexity
    public getProductInfo = async (productViewedPayload: IProductViewed) => {
        const skuId = productViewedPayload.skuId;
        const product = productViewedPayload.product;
        const productId = productViewedPayload.productId;

        let productInfo;
        let skuInfo;

        if (skuId) {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const data = <any>await query(serviceQueries.GET_PRODUCT_DATA, {
                filterBy: { skus: { skuIds: [skuId] } }
            });
            const skuIdMap = data.reduce(mapProductSkusById, {});
            productInfo = skuIdMap[skuId];
            skuInfo = skuIdMap[skuId];
        } else {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            let data: { skus: any[] }[] = [];
            if (productId) {
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                data = <any>await query(serviceQueries.GET_PRODUCT_DATA, {
                        filterBy: { productIds: [productId] }
                    }) || [];
            } else if (product) {
                data = [product];
            }

            if (data.length) {
                productInfo = data[0];
                skuInfo = data[0].skus[0];
            }
        }

        if (!productInfo) {
            return {};
        }

        /* eslint-disable  @typescript-eslint/no-explicit-any */
        const productInfoPayload: any = {
            product_base_id: [productInfo.productId],
            product_category: productInfo.defaultCategory ? [productInfo.defaultCategory.id] : [],
            product_category_id: productInfo.defaultCategory
                ? [productInfo.defaultCategory.id]
                : [],
            product_category_name: productInfo.defaultCategory
                ? [productInfo.defaultCategory.value]
                : [],
            product_id: [`PROD${productInfo.productId.toString()}`],
            product_name: [productInfo.displayName.toString()],
            product_price: skuInfo.prices && skuInfo.prices.length ? [skuInfo.prices[0].price] : [],
            product_product_code: [skuInfo.skuId],
            product_shade: skuInfo.shades && skuInfo.shades.length ? [skuInfo.shades[0].name] : [],
            product_short_desc: [productInfo.shortDescription.toString()],
            product_size: skuInfo.sizes && skuInfo.sizes.length ? [skuInfo.sizes[0].label] : [],
            product_sku: [`SKU${skuInfo.perlgem.SKU_BASE_ID.toString()}`],
            product_sku_large_image_url:
                skuInfo.media && skuInfo.media.large.length ? [skuInfo.media.large[0].src] : [],
            product_sku_small_image_url:
                skuInfo.media && skuInfo.media.small.length ? [skuInfo.media.small[0].src] : [],
            product_small_image_url:
                productInfo.media && productInfo.media.small.length
                    ? [productInfo.media.small[0].src]
                    : [],
            product_upc: [skuInfo.upc],
            product_url: [productInfo.productUrl]
        };

        return productInfoPayload;
    };

    // TODO: this needs to return product data specific to a customer cart interaction, ie add to bag
    public getCartProductInfo = async (cartProductPayload: IProduct) => {
        // TODO: I moved this from the above getProductInfo function
        /*if (event.name === events.ITEM_ADDED || event.name === events.REMOVE_ITEM) {
            productInfoPayload.event_label = `${productInfo.displayName.toString()} - PROD${productInfo.productId.toString()}`;
        }*/

        return {};
    };

    // TODO: determine an easier way to map product data to the data layer format - maybe see how this is done in search DataMappings
    // TODO: this should be if an event needs the entire cart
    public getCartProductsInfo = async (cartProductsPayload: ICartProducts) => {
        const skus = cartProductsPayload.skuIds;
        if (!root.ServiceRegistry['elc-service-cart']) {
            return;
        }
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        let productsInfo: any = {};
        let skuIds: string[];

        if (skus) {
            skuIds = skus;
        } else {
            skuIds = await query(serviceQueries.CART_SKUS_IDS);
        }

        const cartProducts = skuIds.length
            ? // eslint-disable-next-line  @typescript-eslint/no-explicit-any
              <any>await query(serviceQueries.GET_PRODUCT_DATA, {
                  filterBy: { skus: { skuIds } }
              })
            : [];
        const skuIdMap = cartProducts.reduce(mapProductSkusById, {});

        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const cartItems: any = Object.keys(skuIdMap)
            .filter((sku) => skuIds.indexOf(sku) !== -1)
            .reduce((acc, next) => ({ ...acc, [next]: skuIdMap[next] }), {});

        const isCartEmpty = Object.keys(cartItems).length === 0;

        if (!isCartEmpty) {
            productsInfo = {
                cart_product_category: [],
                cart_product_category_name: [],
                cart_product_id: [],
                cart_product_name: [],
                cart_product_price: [],
                cart_product_shade: [],
                cart_product_size: [],
                cart_product_sku: [],
                cart_product_url: [],
                cart_product_is_preorder: [],
                cart_product_quantity: [],
                cart_product_discount_amount: [],
                cart_product_bronto_image: ''
            };

            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const cart = <any>await query(serviceQueries.GET_CART, {});

            const cartItemsQuantity = Object.assign(
                {},
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                ...cart.cartItems.map((sku: any) => ({ [sku.skuId]: sku.quantity }))
            );
            const cartItemsDiscounts = Object.assign(
                {},
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                ...cart.cartItems.map((sku: any) => ({
                    [sku.skuId]: sku.discountsPercent
                        ? ((sku.appliedPriceWithoutTax * sku.discountsPercent) / 100).toFixed(2)
                        : 0
                }))
            );

            Object.keys(cartItems).forEach((key) => {
                const sku = cartItems[key];
                productsInfo.cart_product_category.push(
                    sku.defaultCategory ? sku.defaultCategory.id : ''
                );
                productsInfo.cart_product_category_name.push(
                    sku.defaultCategory ? sku.defaultCategory.value : ''
                );
                productsInfo.cart_product_id.push(`PROD${sku.product_id.toString()}`);
                productsInfo.cart_product_name.push(sku.display_name.toString());
                productsInfo.cart_product_price.push(sku.prices.map((price: any) => price.price));
                productsInfo.cart_product_shade.push(sku.shades.map((shade: any) => shade.name));
                productsInfo.cart_product_size.push(sku.sizes.map((size: any) => size.label));
                productsInfo.cart_product_sku.push(`SKU${sku.perlgem.SKU_BASE_ID.toString()}`);
                productsInfo.cart_product_url.push(sku.url);
                productsInfo.cart_product_is_preorder.push(sku.product_date ? 1 : 0);
                productsInfo.cart_product_quantity.push(cartItemsQuantity[key]);
                productsInfo.cart_product_discount_amount.push(cartItemsDiscounts[key]);
            });
        }

        return productsInfo;
    };

    // TODO: determine an easier way to map product data to the data layer format - maybe see how this is done in search DataMappings
    public getShortProductInfo = async (productPayload: IProduct) => {
        const skuId = productPayload.skuId;
        const productId = productPayload.productId;

        let productInfo;
        let skuInfo;

        if (skuId) {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const data = <any>await query(serviceQueries.GET_PRODUCT_DATA, {
                filterBy: { skus: { skuIds: [skuId] } }
            });
            const skuIdMap = data.reduce(mapProductSkusById, {});
            productInfo = skuIdMap[skuId];
            skuInfo = skuIdMap[skuId];
        } else if (productId) {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const data = <any>await query(serviceQueries.GET_PRODUCT_DATA, {
                filterBy: { productIds: [productId] }
            });
            if (data.length) {
                productInfo = data[0];
                skuInfo = data[0].skus[0];
            }
        }

        if (!productInfo) {
            return {};
        }

        const shortDescription = productInfo.short_description
            ? productInfo.short_description.toString()
            : '';
        const productIdString = productInfo.product_id ? productInfo.product_id.toString() : '';
        const displayName = productInfo.display_name ? productInfo.display_name.toString() : '';
        const skuBaseId = skuInfo.perlgem.SKU_BASE_ID ? skuInfo.perlgem.SKU_BASE_ID.toString() : '';

        return {
            product_base_id: [productInfo.product_id],
            product_category_name: productInfo.defaultCategory
                ? [productInfo.defaultCategory.value]
                : [],
            product_id: [`PROD${productIdString}`],
            product_price: skuInfo.prices.map((price: any) => price.price),
            product_name: [displayName],
            product_shade: skuInfo.shades.map((shade: any) => shade.name),
            product_short_desc: [shortDescription],
            product_size: skuInfo.sizes.map((size: any) => size.label),
            product_sku: [`SKU${skuBaseId}`]
        };
    };

    public getProductImpresionsInfo = async (productImpressionsPayload: IProductImpressions) => {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        let filteredItemsList: any;

        if (!productImpressionsPayload.products) {
            const skuIds = productImpressionsPayload.skuIds || [];
            const productIds = productImpressionsPayload.productIds || [];
            const productTags = productImpressionsPayload.productTags || [];

            if (!skuIds.length && !productIds.length && !productTags.length) {
                return {};
            }

            const skuIdList = skuIds.slice(0);
            const productIdList = productIds.slice(0);
            const productTagsList = productTags.slice(0);

            const tagList = isTagId(productTagsList)
                ? { ids: productTags.slice(0) }
                : { keys: productTags.slice(0) };

            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const skuList = skuIdList.length ? <any>await query(serviceQueries.GET_PRODUCT_DATA, {
                      filterBy: { skus: { skuIds: skuIdList } }
                  }) : [];

            const skuIdMap = skuList.reduce(mapProductSkusById, {});

            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const productList =
                productIdList.length || productTagsList.length
                    ? <any>await query(serviceQueries.GET_PRODUCT_DATA, {
                          filterBy: {
                              productIds: productIdList,
                              tags: { ...tagList, includeInnerHits: true }
                          },
                          sortBy: {
                              tags: { productDisplayOrder: 'ASCENDING' }
                          }
                      })
                    : [];

            filteredItemsList = Object.keys(skuIdMap)
                .filter((key) => skuIds.includes(key))
                .map((key) => skuIdMap[key])
                .concat(productList);
        } else {
            filteredItemsList = productImpressionsPayload.products;
        }

        if (filteredItemsList.length === 0) {
            return {};
        }

        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const impressionsInfo: any = {
            product_impression_base_id: [],
            product_impression_id: [],
            product_impression_name: [],
            product_impression_position: [],
            product_impression_shade: [],
            product_impression_size: [],
            product_impression_price: [],
            product_impression_sku_large_image_url: [],
            product_impression_sku_small_image_url: [],
            product_impression_small_image_url: [],
            product_impression_url: [],
            product_impression_short_desc: [],
            product_impression_sku: [],
            product_impression_product_code: [],
            product_impression_category: [],
            product_impression_category_id: [],
            product_impression_category_name: [],
            product_impression_list: [],
            product_impression_upc: []
        };

        /*
        // need to determine source for these
        product_impression_brand: [],
        product_impression_was_price: []
        */

        Object.keys(filteredItemsList).forEach((key, index) => {
            const item = filteredItemsList[key];

            let product;
            let sku;

            if (typeof item[$mobx] === 'object') {
                //product request
                product = item;
                sku = item.skus[0];
            } else {
                //sku request
                product = item;
                sku = item;
            }

            if (typeof sku === 'undefined') {
                return;
            }

            impressionsInfo.product_impression_base_id.push(Number(product.productId));
            impressionsInfo.product_impression_id.push(`PROD${product.productId.toString()}`);
            impressionsInfo.product_impression_name.push(product.displayName.toString());
            impressionsInfo.product_impression_position.push(index + 1);
            impressionsInfo.product_impression_shade.push(
                sku.shades && sku.shades.length ? sku.shades[0].name : ''
            );
            impressionsInfo.product_impression_size.push(
                sku.sizes && sku.sizes.length ? sku.sizes[0].label : ''
            );
            impressionsInfo.product_impression_price.push(sku.prices[0].price.toString());
            impressionsInfo.product_impression_sku_large_image_url.push(
                sku.media && sku.media.large.length ? sku.media.large[0].src : ''
            );
            impressionsInfo.product_impression_sku_small_image_url.push(
                sku.media && sku.media.small.length ? sku.media.small[0].src : ''
            );
            impressionsInfo.product_impression_small_image_url.push(
                product.media && product.media.small.length ? product.media.small[0].src : ''
            );
            impressionsInfo.product_impression_url.push(product.productUrl);
            impressionsInfo.product_impression_short_desc.push(product.shortDescription);
            impressionsInfo.product_impression_category.push(
                product.defaultCategory ? product.defaultCategory.id : ''
            );
            impressionsInfo.product_impression_category_id.push(
                product.defaultCategory ? product.defaultCategory.id : ''
            );
            impressionsInfo.product_impression_category_name.push(
                product.defaultCategory ? product.defaultCategory.value : ''
            );
            impressionsInfo.product_impression_sku.push(`SKU${sku.perlgem.SKU_BASE_ID}`);
            impressionsInfo.product_impression_product_code.push(sku.skuId);
            impressionsInfo.product_impression_list.push(
                productImpressionsPayload.listOverride || root.location.pathname
            );

            impressionsInfo.product_impression_upc.push(sku.upc);
            // need to confirm that originalPrice is correct for this mapping
            //impressionsInfo.product_impression_was_price.push(sku.prices[0].originalPrice?.toString());
            // need to confirm where to get this data
            //impressionsInfo.product_impression_brand.push('');
        });

        return {
            ...impressionsInfo
        };
    };
}
