import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class AppointmentEditClickedUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const appointmentEditPayload = {
            event_name: 'appointment booking',
            event_category: 'appointment booking',
            event_action: 'review',
            event_label: 'edit'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.APPOINTMENT_EDIT_CLICKED,
            type: 'link',
            payload: appointmentEditPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
