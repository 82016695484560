import { EventRepository } from '../repositories/EventRepository';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { events } from '@estee/elc-service-bus';

export class StoreMarkerClickedUseCase {
    private eventRepository: EventRepository;
    private storeId: string;

    constructor(storeId: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.storeId = storeId;
    }

    public execute = async () => {
        const storeMarkerClickedPayload = {
            event_action: `${events.STORE_MARKER_CLICKED}`,
            event_label: `${this.storeId}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.STORE_MARKER_CLICKED,
            type: 'link',
            payload: storeMarkerClickedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
