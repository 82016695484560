import { EventRepository } from '../../internal/repositories/EventRepository';
import * as views from '../../constants/Views';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { query, serviceQueries } from '@estee/elc-service-bus';

export class CartLandingPageViewedUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const skuIds = await query(serviceQueries.RECOMMENDED_PRODUCTS_SKUID_LIST);
        const cartLandingPageViewedPayload = {
            active_checkout_panel: 'checkout'
        };

        const productImpressionsPayload = {
            skuIds: skuIds,
            listOverride: '/cart'
        };

        const newEvent = new Event({
            name: views.CART_VIEW,
            payload: cartLandingPageViewedPayload,
            type: 'data',
            id: this.eventRepository.generateId(),
            data: {
                // TODO: figure out what to do with these values
                /*requiredData: [
                    'cart_total_value',
                    'cart_total_items',
                    'order_promo_code'
                ],*/

                productImpressions: productImpressionsPayload
            }
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
