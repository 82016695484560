import { Event } from '../domain/entities/Event';
import { IUtagData } from '@estee/elc-universal-utils';
import { computed, action, observable } from 'mobx';

export class EventRepository {
    @observable public events: Event[] = [];
    private idCounter: number = 1000;

    @action
    public generateId = () => {
        this.idCounter = this.idCounter + 1;

        return `event-${this.idCounter}-${Math.floor(Math.random() * 10000)}`;
    };

    @action
    public prependEventToQueue = (event: Event) => {
        this.events.unshift(event);
    };

    @action
    public addEventToQueue = (event: Event) => {
        this.events.push(event);
    };

    @action
    public removeEventFromQueue = (id: string) => {
        this.events = this.events.filter((event) => event.id !== id);
    };

    public markEventAsComplete = (id: string, missingInfos: IUtagData) => {
        const index = this.events.findIndex((e) => e.id === id);
        const markedEvent = this.events[index];
        if (!markedEvent) {
            return;
        }
        markedEvent.addPayload(missingInfos);
    };

    @computed
    public get completeEvents() {
        return this.events.filter((event) => event.isComplete);
    }

    @computed
    public get incompleteEvents() {
        return this.events.filter((event) => !event.isComplete);
    }

    @computed
    public get incompleteViewEvents() {
        return this.events.filter((event) => !event.isComplete && event.type === 'view');
    }

    @computed
    public get incompleteLinkEvents() {
        return this.events.filter((event) => !event.isComplete && event.type === 'link');
    }

    @computed
    public get incompleteDataEvents() {
        return this.events.filter((event) => !event.isComplete && event.type === 'data');
    }

    @computed
    public get hasEventsToSubmit() {
        return this.completeEvents.length > 0;
    }

    @computed
    public get hasIncompleteEvents() {
        return this.incompleteEvents.length > 0;
    }

    @computed
    public get hasIncompleteViewEvents() {
        return this.incompleteViewEvents.length > 0;
    }

    @computed
    public get hasIncompleteLinkEvents() {
        return this.incompleteLinkEvents.length > 0;
    }

    @computed
    public get hasIncompleteDataEvents() {
        return this.incompleteDataEvents.length > 0;
    }
}
