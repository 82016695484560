import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { SkuEngraving } from '../../constants/SkuEngraving';
import { IEngravingData } from '../interfaces/IAnalytics';

export class SkuEngravingUseCase {
    private eventRepository: EventRepository;
    private engravingData: IEngravingData;

    constructor(engravingData: IEngravingData) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.engravingData = engravingData;
    }

    private getEngravingEvent = () => {
        const {
            EVENT_ACTION,
            EVENT_CATEGORY,
            EVENT_LABEL,
            EVENT_NAME,
            EVENT_NONINTERACTION
        } = SkuEngraving;

        return {
            event_action: EVENT_ACTION,
            event_category: EVENT_CATEGORY,
            event_label: EVENT_LABEL,
            event_name: EVENT_NAME,
            event_noninteraction: EVENT_NONINTERACTION
        };
    };

    public execute = async () => {
        // TODO: this data is being passed in - do we need productShort still? Can we just use this data?
        //const { skuId, skuBaseId, size, price, name } = this.engravingData;
        const { skuId, skuBaseId } = this.engravingData;

        const engravingPayload = {
            ...this.getEngravingEvent(),
            enh_action: 'detail',
            page_name: `Engraving | ${skuId} | SKU${skuBaseId}`
        };

        const productShortPayload = {
            skuId: skuId
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SKU_ENGRAVING_TRIGGERED,
            type: 'link',
            payload: engravingPayload,
            data: {
                productShort: productShortPayload
            }
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
