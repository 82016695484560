import { EventRepository } from '../repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { IReviewsData } from '../interfaces/IAnalytics';

export class RatingsReviewsUseCase {
    private eventRepository: EventRepository;
    private event: string;
    private reviewsData: IReviewsData;

    constructor(event: string, reviewsData: IReviewsData) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.reviewsData = reviewsData;
        this.event = event;
    }

    public execute = () => {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: this.event,
            type: 'link',
            payload: this.getRatingsReviewsPayload()
        });

        this.eventRepository.addEventToQueue(newEvent);
    };

    private getRatingsReviewsPayload() {
        const { id, name, reviewCategory, selectedValue, eventAction } = this.reviewsData;

        return {
            event_name: 'read_write_review',
            event_category: reviewCategory,
            event_action: selectedValue ? `${eventAction} - ${selectedValue}` : eventAction,
            event_label: `${name} - ${id}`
        };
    }
}
