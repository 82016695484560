import { events, query, serviceQueries } from '@estee/elc-service-bus';

import { EventRepository } from '../repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

interface IAnalyticsPayload {
    cart_product_id: string[];
    cart_product_price: number[];
    cart_product_quantity: number[];
    cart_product_shade: string[];
    cart_product_size: string[];
    cart_product_sku: string[];
    cart_product_is_replenishment_item: string[];
    cart_product_product_code: string[];
    cart_product_upc: string[];
}

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
const CartDataToAnalyticsPayload = (cartItems: any[]): IAnalyticsPayload =>
    cartItems.reduce(
        (acc, cartItem) => ({
            cart_product_id: [...(acc.cart_product_id || []), cartItem.product.productId],
            cart_product_price: [...(acc.cart_product_price || []), cartItem.unitPriceWithTax],
            cart_product_quantity: [...(acc.cart_product_quantity || []), cartItem.quantity],
            cart_product_shade: [...(acc.cart_product_shade || []), cartItem.product.shade],
            cart_product_size: [...(acc.cart_product_size || []), cartItem.product.size],
            cart_product_sku: [...(acc.cart_product_sku || []), cartItem.skuId],
            cart_product_is_replenishment_item: [],
            cart_product_product_code: [],
            cart_product_replenishment_frequency: [],
            cart_product_upc: []
        }),
        {} as IAnalyticsPayload
    );

export class CartOverlayOpened {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const cart = (await query(serviceQueries.GET_CART, {})) as any;

        const payload = {
            event_name: 'checkout_option',
            enh_action: 'checkout',
            event_category: 'cart',
            event_action: 'view',
            checkout_step: '1',
            checkout_type: 'react',
            location: '/checkout/viewcart.tmpl',
            ...CartDataToAnalyticsPayload(cart.cartItems)
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.CART_OVERLAY_OPENED,
            type: 'link',
            payload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
