import { EventRepository } from '../../internal/repositories/EventRepository';
import { events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { IOrderQueryData } from '../../service-setup/ServiceBusController';

export class TransactionSuccededUseCase {
    private eventRepository: EventRepository;
    private orderQueryData: IOrderQueryData;

    constructor(orderQueryData: IOrderQueryData) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.orderQueryData = orderQueryData;
    }

    public execute = async () => {
        // TODO: can retrieval of this order data be moved to DataAggregator? Or can it be passed in?
        const totalCountOfOrders = await query(serviceQueries.TOTAL_COUNT_OF_ORDERS);
        const orderQuery = await query(serviceQueries.GET_ORDER_DATA, this.orderQueryData);

        const { order } = orderQuery;

        const event_info = {
            event_name: 'transaction',
            event_category: 'ecommerce',
            event_action: 'transaction',
            event_label: `${order.orderNumber}`
        };

        const transactionSuccededPayload = {
            ...event_info,
            order_currency_code: order.currency.isoCode,
            order_discount_amount: order.totals.totalDiscounts,
            order_grand_total: order.totals.totalWithTax,
            order_id: order.id,
            order_merchandise_total: order.totals.subTotalWithTax,
            order_payment_type: this.orderQueryData.paymentType,
            order_shipping_amount: order.shippingInfo[0].priceWithTax,
            order_subtotal: order.totals.subTotalWithTax,
            order_tax_amount: order.totals.tax,
            order_total_product_units: order.items.length,
            order_type: order.orderType,
            orders_compare: '',
            orders_count: totalCountOfOrders,
            orders_total: order.totals.subTotalWithTax
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.TRANSACTION_SUCCEDED,
            type: 'link',
            payload: transactionSuccededPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
