import { EventRepository } from '../../internal/repositories/EventRepository';
import { events, serviceQueries, query } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { PRODUCT_CLICKED } from '../../constants/UseCasesConstants';
import { root } from '@estee/elc-universal-utils';
import { mapProductSkusById } from '../../utils/dataAggregator';
import { ISkuData } from './ProductNotifyMeTriggeredUseCase';

export interface IProductClickedPayload {
    [key: string]: string | string[] | number[];
}

export class ProductClickedUseCase {
    private eventRepository: EventRepository;
    private skuId: string;
    private pageType: string;
    private positionIndex: number;

    constructor(skudId: string, pageType: string = '', positionIndex: number) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.skuId = skudId;
        this.pageType = pageType;
        this.positionIndex = positionIndex;
    }

    public execute = async () => {
        // TODO: we shouldn't need to call this here
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const data = <any>await query(serviceQueries.GET_PRODUCT_DATA, {
            filterBy: { skus: { skuIds: [this.skuId] } }
        });
        const skuIdMap: ISkuData = data.reduce(mapProductSkusById, {});
        const productInfo = skuIdMap[this.skuId];

        // TODO: figure out a way to get productInfo from DataAggregator
        const productClickedPayload: IProductClickedPayload = {
            event_name: PRODUCT_CLICKED,
            event_category: 'ecommerce',
            event_action: 'product click',
            enh_action: PRODUCT_CLICKED,
            event_noninteraction: 'FALSE',
            event_label: `${productInfo.display_name} - PROD${productInfo.product_id}`,
            product_position: [this.positionIndex],
            product_list: [root.location.pathname],
            product_id: [`PROD${productInfo.product_id.toString()}`]
        };

        if (!!this.pageType) {
            productClickedPayload.page_name = `${this.pageType} | ${productInfo.display_name}`;
        }

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.PRODUCT_CLICKED,
            type: 'link',
            payload: productClickedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
