import { EventRepository } from '../../internal/repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { events } from '@estee/elc-service-bus';

export interface ISearchProductsQueried {
    searchTerm: string;
    searchType?: string;
    skuIds?: string[];
}

export class SearchProductsQueriedUseCase {
    private eventRepository: EventRepository;
    private searchTerm: string;
    private searchType: string;
    private skuIds: string[];

    constructor(payload: ISearchProductsQueried) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.searchTerm = payload.searchTerm;
        this.searchType = payload.searchType || 'standard';
        this.skuIds = payload.skuIds || [];
    }

    public execute = async () => {
        let location = `?search=${this.searchTerm}&searchtype=${this.searchType}`;

        if (this.searchType === 'predictive') {
            location = `/searchresultsoverlay${location}`;
        } else {
            location = `/search${location}`;
        }

        const productImpressionsPayload = {
            skuIds: this.skuIds,
            listOverride: location
        };

        const searchProductsQueriedPayload = {
            location: location,
            search_keyword: this.searchTerm,
            search_type: this.searchType,
            search_results: this.skuIds.length.toString(),
            site_search_results_found: this.skuIds.length ? 'yes' : 'no',
            Number_of_On_Site_Searches: '1'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SEARCH_PRODUCTS_QUERIED,
            type: 'view',
            payload: searchProductsQueriedPayload,
            data: {
                productImpressions: productImpressionsPayload
            }
        });
        this.eventRepository.addEventToQueue(newEvent);
    };
}
