import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class ItemAddedUseCase {
    private eventRepository: EventRepository;
    private skuId: string;

    constructor(skuId: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.skuId = skuId;
    }

    public execute = () => {
        const itemAddedPayload = {
            event_name: 'add',
            event_category: 'ecommerce',
            event_action: 'add to bag',
            enh_action: 'add',
            Add_to_Bag_Module: 'Add_to_Bag_Module'
        };

        const cartProductPayload = {
            skuId: this.skuId
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.ITEM_ADDED,
            type: 'link',
            payload: itemAddedPayload,
            data: {
                cartProduct: cartProductPayload
            }
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
