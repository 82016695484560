import { events } from '@estee/elc-service-bus';

import { EventRepository } from '../repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class CartOverlayClosed {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = () => {
        const payload = {
            event_name: 'cart overlay',
            event_category: 'ecommerce',
            event_action: 'cart overlay',
            event_label: 'close'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.CART_OVERLAY_CLOSED,
            type: 'link',
            payload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
