import { observable, action } from 'mobx';
import { root, IUtag, IUtagData } from '@estee/elc-universal-utils';

export class AnalyticsDriver {
    public client: IUtag;
    public utagData: IUtagData = root.utag_data;

    @observable public isLoaded: boolean = false;

    constructor() {}

    public trackEvent = (data: IUtagData, cb?: Function) => {
        this.client.link(data, cb);
    };

    public pageTrack = (data: IUtagData, cb?: Function) => {
        this.client.view({ ...this.utagData, ...data }, cb);
    };

    @action
    public enable() {
        if (root.utag) {
            this.client = root.utag;
            this.utagData = root.utag_data;
            this.isLoaded = true;
        }
    }

    @action
    public disable() {
        this.client = <IUtag>{};
        this.isLoaded = false;
    }
}
