import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class ShippingOptionSelectedUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = () => {
        const shippingOptionSelectedPayload = {
            event_name: 'shipping_options',
            event_category: 'ecommerce',
            event_action: 'checkout',
            event_label: 'Shipping Options'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SHIPPING_OPTION_SELECTED,
            type: 'link',
            payload: shippingOptionSelectedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
