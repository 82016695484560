export enum SmartGiftEventAction {
    SMARTGIFT_ADDED = 'launch',
    SMARTGIFT_VIEWED = 'see gift',
    SMARTGIFT_PREPARED = 'prepare my gift',
    SMARTGIFT_WIDGET_TOGGLED = 'minimize / maximize',
    SMARTGIFT_REMOVED = 'remove this item',
    SMARTGIFT_CANCELLED = 'cancel this gift',
    SMARTGIFT_HELP_OPENED = 'help - open',
    SMARTGIFT_HELP_CLOSED = 'help - close'
}
