import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class SampleAddedUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const sampleAddedPayload = {
            event_name: 'samples',
            event_category: 'samples',
            event_action: 'sample added',
            active_checkout_panel: 'cart'
        };

        // TODO: we need to pass in skuId of the sample added
        const cartProductPayload = {
            skuId: ''
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SAMPLE_ADDED,
            type: 'link',
            payload: sampleAddedPayload,
            data: {
                cartProduct: cartProductPayload
            }
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
