import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class AppointmentProcessCompletedUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const appointmentProcessCompletedPayload = {
            page_url: '/book-appointment/complete',
            page_name: 'Book Appointment - Complete',
            location: `${window.location.origin}/book-appointment/complete`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.APOOINTMENT_PROCESS_COMPLETED,
            type: 'view',
            payload: appointmentProcessCompletedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
