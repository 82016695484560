import { observable, action } from 'mobx';
import { IEvent, IEventType, IEventData } from '../../interfaces/IAnalytics';
import { IUtagData } from '@estee/elc-universal-utils';

export class Event {
    @observable public isComplete: boolean = false;
    @observable public isExecuting: boolean = false;
    @observable public name: string;
    @observable public payload: IUtagData;
    @observable public type: IEventType;
    @observable public id: string;
    @observable public data: IEventData;

    constructor(event: IEvent) {
        this.id = event.id;
        this.name = event.name;
        this.type = event.type;
        this.data = event.data || {};
        this.payload = event.payload || {};
    }

    @action
    private markEventAsCompleted = () => {
        this.isComplete = true;
        this.isExecuting = false;
    };

    @action
    public markEventAsExecuting = () => {
        this.isExecuting = true;
    };

    @action
    public addPayload = (missingInfo: IUtagData) => {
        this.payload = { ...this.payload, ...missingInfo };
        this.markEventAsCompleted();
    };
}
