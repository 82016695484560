import { EventRepository } from '../repositories/EventRepository';
import { serviceQueries, query } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { ISkuData } from './ProductNotifyMeTriggeredUseCase';
import { mapProductSkusById } from '../../utils/dataAggregator';

export const EVENT_NAME = 'notify_me';
export const EVENT_CATEGORY = 'ecommerce';

export class ProductNotifyMeRequestCompletedUseCase {
    private eventRepository: EventRepository;
    private skuId: string;
    private event: string;
    private eventAction: string;

    constructor(skudId: string, event: string, eventAction: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.skuId = skudId;
        this.event = event;
        this.eventAction = eventAction;
    }

    public execute = async () => {
        // TODO: are we requesting this data here just to get the name and id? if so, can't we pass it that data in?
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const data = <any>await query(serviceQueries.GET_PRODUCT_DATA, {
            filterBy: { skus: { skuIds: [this.skuId] } }
        });
        const skuIdMap: ISkuData = data.reduce(mapProductSkusById, {});
        const productInfo = skuIdMap[this.skuId];

        const productNotifyMeRequestCompletedPayload = {
            event_name: EVENT_NAME,
            event_action: this.eventAction,
            event_category: EVENT_CATEGORY,
            event_label: `${productInfo.display_name} - PROD${productInfo.product_id}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: this.event,
            type: 'link',
            payload: productNotifyMeRequestCompletedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
