import { AnalyticsDriver } from './../internal/AnalyticsDriver';
import { EventSubmitter } from '../internal/EventSubmitter';
import { ConfigStore } from './ConfigStore';
import { Container } from 'hypo-container';
import { ServiceBusController } from './ServiceBusController';
import { AnalyticsService } from './AnalyticsService';
import { getDecorators } from '@estee/elc-service';
import { EventRepository } from '../internal/repositories/EventRepository';
import { DataAggregator } from '../internal/DataAggregator';
import { OutboundLinkListener } from '../internal/OutboundLinkListener';

export const diContainer = new Container();

export const serviceNames = {
    configStore: 'CONFIG_STORE',
    serviceBusController: 'SERVICE_BUS_CONTROLLER',
    analyticsService: 'ANALYTICS_SERVICE',
    analyticsDriver: 'ANALYTICS_DRIVER',
    eventSubmitter: 'EVENT_SUBMITTER',
    eventRepository: 'EVENT_REPOSITORY',
    dataAggregator: 'DATA_AGGREGATOR',
    outboundLinkListener: 'OUTBOUND_LINK_LISTENER'
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const setConfig = (configJSON: any) => {
    diContainer.config = configJSON;
};

diContainer
    .register(
        serviceNames.configStore,
        diContainer.factory((c) => new ConfigStore(c.config))
    )
    .register(serviceNames.eventRepository, (c) => new EventRepository())
    .register(
        serviceNames.dataAggregator,
        (c) => new DataAggregator(c.get(serviceNames.eventRepository))
    )

    .register(serviceNames.serviceBusController, (c) => new ServiceBusController())
    .register(serviceNames.analyticsDriver, (c) => new AnalyticsDriver())
    .register(
        serviceNames.eventSubmitter,
        (c) =>
            new EventSubmitter(
                c.get(serviceNames.analyticsDriver),
                c.get(serviceNames.eventRepository)
            )
    )
    .register(
        serviceNames.analyticsService,
        (c) =>
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            new AnalyticsService((config: any) => {
                setConfig(config);
                diContainer.get(serviceNames.dataAggregator);
                diContainer.get(serviceNames.eventSubmitter);

                return c.get(serviceNames.serviceBusController);
            })
    )
    .register(serviceNames.outboundLinkListener, (c) => new OutboundLinkListener());

diContainer.get(serviceNames.analyticsService).register();

const { lazyInject } = getDecorators(diContainer);

export { lazyInject };
