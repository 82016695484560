import { EventRepository } from '../repositories/EventRepository';
import { GRID_ACTIONS, EVENT_NAME, EVENT_CATEGORY } from './GridFilteredUseCase';
import { events } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';

export class GridSortedUseCase {
    private eventRepository: EventRepository;
    private appliedSort: string;

    constructor(appliedSort: string) {
        this.appliedSort = appliedSort;
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const eventLabel = `${GRID_ACTIONS.sort} - ${this.appliedSort
            .replace(/_/g, '')
            .replace('sort', '')}`;

        const sortGridPayload = {
            event_name: EVENT_NAME,
            event_category: EVENT_CATEGORY,
            event_action: GRID_ACTIONS.sort,
            event_label: eventLabel
        };

        const sortEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.GRID_SORTED,
            type: 'link',
            payload: sortGridPayload
        });

        this.eventRepository.addEventToQueue(sortEvent);
    };
}
