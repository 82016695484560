import { EventRepository } from '../../internal/repositories/EventRepository';
import * as views from '../../constants/Views';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class ShippingPageViewedUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = () => {
        const shippingPageViewed = {
            active_checkout_panel: 'checkout'
        };

        // TODO: figure out if this should be type "view"
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: views.SHIPPING_VIEW,
            type: 'data',
            payload: shippingPageViewed
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
