import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class ShadeGroupSelectedUseCase {
    private eventRepository: EventRepository;
    private shadeGroupKey: string;
    private eventName: string;
    private eventAction: string;

    constructor(shadeGroupKey: string, eventName: string, eventAction: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.shadeGroupKey = shadeGroupKey;
        this.eventName = eventName;
        this.eventAction = eventAction;
    }

    public execute = () => {
        const payload = {
            event_name: this.eventName,
            event_category: 'filter & sort selection ',
            event_action: this.eventAction,
            event_label: this.shadeGroupKey
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.PRODUCT_SHADE_GROUP_SELECTED,
            type: 'link',
            payload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
