import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export interface IAnalyticsFilters {
    category: string;
    option: string;
}

export enum GRID_ACTIONS {
    filter = 'filter',
    sort = 'sort'
}

export const EVENT_NAME = 'filters_and_sort_selection';
export const EVENT_CATEGORY = 'filter & sort selection';

export class GridFilteredUseCase {
    private eventRepository: EventRepository;
    private appliedFilters: IAnalyticsFilters[];

    constructor(appliedFilters: IAnalyticsFilters[]) {
        this.appliedFilters = appliedFilters;
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        let eventLabel: string = '';

        eventLabel = this.appliedFilters.reduce(
            (previousValue: string, filter: IAnalyticsFilters) => {
                return `${previousValue} ${filter.category} - ${filter.option} | `;
            },
            ''
        );

        const filterGridPayload = {
            event_name: EVENT_NAME,
            event_category: EVENT_CATEGORY,
            event_action: GRID_ACTIONS.filter,
            event_label: eventLabel.slice(0, -3)
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.GRID_FILTERED,
            type: 'link',
            payload: filterGridPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
