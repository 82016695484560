import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { IProductImpressions } from '../interfaces/IAnalytics';

export class ProductImpressionsUseCase {
    private eventRepository: EventRepository;
    private productImpressionsPayload: IProductImpressions;

    constructor(productImpressionsPayload: IProductImpressions) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.productImpressionsPayload = productImpressionsPayload;
    }

    public execute = async () => {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.ANALYTICS_PRODUCTS_VIEWED,
            type: 'link',
            payload: {
                event_name: 'product_impression',
                event_category: 'ecommerce',
                event_action: 'view_item_list',
                event_label: 'view_item_list',
                event_noninteraction: true
            },
            data: {
                productImpressions: this.productImpressionsPayload
            }
        });
        this.eventRepository.addEventToQueue(newEvent);
    };
}
