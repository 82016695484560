import { EventRepository } from '../repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

const eventActionMap: { [key: string]: string } = {
    offers: 'offer tab - cart overlay',
    cart: 'cart tab - cart overlay'
};

interface ICartTabTabClickedProps {
    tabId: string;
    event: string;
}

export class CartTabTabClicked {
    private eventRepository: EventRepository;
    private _tabId: string;
    private _event: string;

    constructor({ tabId, event }: ICartTabTabClickedProps) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this._tabId = tabId;
        this._event = event;
    }

    public execute = () => {
        const offerCodeFailedPayload = {
            event_name: 'cart overlay',
            event_category: 'ecommerce',
            event_action: eventActionMap[this._tabId] || this._tabId,
            event_label: 'click'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: this._event,
            type: 'link',
            payload: offerCodeFailedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
