import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { ActionType } from '../../constants/ActionType';

export class SkuPickerInteractionUseCase {
    private eventRepository: EventRepository;
    private productId: string;
    private action: string;

    constructor(productId: string, action: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.productId = productId;
        this.action = action;
    }

    public execute = () => {
        const eventName =
            this.action === ActionType.OPEN ? events.SKU_PICKER_OPENED : events.SKU_PICKER_CLOSED;
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: eventName,
            type: 'link',
            payload: this.getSkuPickerPayload()
        });

        this.eventRepository.addEventToQueue(newEvent);
    };

    private getSkuPickerPayload() {
        return {
            event_name: `sku picker ${this.action}`,
            event_category: 'sku picker',
            event_action: `${this.action}`,
            event_label: `PROD${this.productId}`
        };
    }
}
