import { EventRepository } from '../repositories/EventRepository';
import { events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export interface IAnalyticsFilters {
    category: string;
    option: string;
}

export enum FILTER_METHODS {
    filter = 'filter',
    sort = 'sort'
}

export class MppFilteredUseCase {
    private eventRepository: EventRepository;
    private method: string;

    constructor(method: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.method = method;
    }

    public execute = async () => {
        let event_label: string = '';

        // TODO: can we pass the filters in instead of querying for them?
        if (this.method === FILTER_METHODS.filter) {
            const filters = <{ category: string; option: string }[]>(
                await query(serviceQueries.GET_SELECTED_FILTERS, {})
            );
            filters.forEach((filter: IAnalyticsFilters) => {
                event_label += `${filter.category} - ${filter.option} | `;
            });
        } else {
            const filters = <string>await query(serviceQueries.GET_SORTBY_VALUE, {});
            event_label += `${this.method} - ${filters.replace(/_/g, '').replace('sort', '')}   `;
        }

        const filterMppPayload = {
            event_name: 'filters_and_sort_selection',
            event_category: 'filter & sort selection',
            event_action: this.method,
            event_label: event_label.slice(0, -3)
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.MPP_FILTERED,
            type: 'link',
            payload: filterMppPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
