import { EventRepository } from '../../internal/repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export interface IVtoAction {
    event: string;
    pageType: string;
    vtoType: string;
}

export class VTOActionUseCase {
    private eventRepository: EventRepository;
    private event: string;
    private pageType: string;
    private vtoType: string;

    constructor(vtoAction: IVtoAction) {
        const { event, pageType, vtoType = '' } = vtoAction;

        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.event = event;
        this.pageType = pageType;
        this.vtoType = vtoType;
    }

    public execute = () => {
        const vtoActionPayload = {
            event_name: 'YouCam',
            event_category: 'YouCam',
            event_action: this.event,
            event_label: 'event',
            modiface_evar: `YouCam |  ${this.event}  | event`,
            page_name: `${this.pageType} |  ${this.event}  | event`,
            vto_type: this.vtoType
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: this.event,
            type: 'link',
            payload: vtoActionPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
