import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { EventRepository } from '../repositories/EventRepository';
import { Event } from '../domain/entities/Event';
import { IAccountSidebarBaseData } from '../interfaces/IAnalytics';

export class AccountSidebarMenuOptionClickedUseCase {
    private eventRepository: EventRepository;
    private accountSidebarData: IAccountSidebarBaseData;

    constructor(accountSidebarData: IAccountSidebarBaseData) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.accountSidebarData = accountSidebarData;
    }

    public execute() {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: this.accountSidebarData.eventAction,
            type: 'link',
            payload: this.getAccountSidebarPayload()
        });

        this.eventRepository.addEventToQueue(newEvent);
    }

    private getAccountSidebarPayload() {
        const { eventAction, label } = this.accountSidebarData;

        return {
            event_name: 'my account page',
            event_category: 'my account page',
            event_action: eventAction,
            event_label: label
        };
    }
}
