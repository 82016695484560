import { EventRepository } from '../../internal/repositories/EventRepository';
import { events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import * as routes from '../../constants/Routes';

export class CreateAccountSuccessUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        // TODO: can this be passed in instead of querying for it?
        const routerStatus = await query(serviceQueries.GET_CURRENT_ROUTE);
        const currentRoute = routerStatus.currentRoute;
        const isAccountPage =
            currentRoute === routes.ACCOUNT_LANDING || currentRoute === routes.LOGIN;

        const eventInfo = {
            event_name: 'create_account_success',
            event_category: 'account',
            event_action: 'create account success'
        };

        const createAccountSuccessPayload = {
            join_date: new Date(Date.now()).toISOString().split('T')[0],
            join_date_year_month: new Date(Date.now()).toISOString().substr(0, 7),
            length_of_relationship: 0,
            registration_date: new Date(Date.now()).toISOString(),
            ...eventInfo
        };
        Object.assign(
            createAccountSuccessPayload,
            !isAccountPage ? { active_checkout_panel: 'order_confirmation' } : {}
        );

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.CREATE_ACCOUNT_SUCCESS,
            type: 'link',
            payload: createAccountSuccessPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
