import { serviceQueries } from '@estee/elc-service-bus';
const queryMap = new Map();

queryMap.set('isAuthenticated', 'isAuthenticated');
queryMap.set('get.config', 'get.config');
queryMap.set('cartItemsCounter', serviceQueries.CART_ITEMS_COUNT);
queryMap.set('saveForLaterItemsCount', serviceQueries.SAVE_FOR_LATER_ITEMS_COUNT);
queryMap.set('recognized_user', serviceQueries.IS_AUTHENTICATED);
queryMap.set('signed_in', serviceQueries.IS_AUTHENTICATED);
queryMap.set('registered_user', serviceQueries.IS_AUTHENTICATED);
queryMap.set('birth_date_onfile', serviceQueries.BIRTH_DATE);
queryMap.set('email_signup', serviceQueries.EMAIL_OPT_IN);
queryMap.set('opt_in_state', serviceQueries.EMAIL_OPT_IN);
queryMap.set('pc_email_optin', serviceQueries.EMAIL_OPT_IN);
queryMap.set('ELC_USER_ID', serviceQueries.USER_ID);
queryMap.set('USER_ID', serviceQueries.USER_ID);
queryMap.set('first_order_date', serviceQueries.FIRST_ORDER_DATE);
queryMap.set('last_order_date', serviceQueries.LAST_ORDER_DATE);
queryMap.set('first_order_value', serviceQueries.FIRST_ORDER_VALUE);
queryMap.set('first_order_value_bucket', serviceQueries.FIRST_ORDER_VALUE);
queryMap.set('last_order_value', serviceQueries.LAST_ORDER_VALUE);
queryMap.set('orders_avg', serviceQueries.ORDERS_AVG);
queryMap.set('total_count_of_orders', serviceQueries.TOTAL_COUNT_OF_ORDERS);
queryMap.set('total_count_of_orders_bucket', serviceQueries.TOTAL_COUNT_OF_ORDERS);
queryMap.set('crm_total_count_of_orders_bucket', serviceQueries.TOTAL_COUNT_OF_ORDERS);
queryMap.set('total_sum_of_revenue', serviceQueries.TOTAL_SUM_OF_REVENUE);
queryMap.set('total_sum_of_revenue_bucket', serviceQueries.TOTAL_SUM_OF_REVENUE);
queryMap.set('crm_total_sum_of_revenue_bucket', serviceQueries.TOTAL_SUM_OF_REVENUE);
queryMap.set('aov', serviceQueries.ORDERS_AVG);
queryMap.set('cart_total_value', serviceQueries.CART_ITEMS_SUBTOTAL);
queryMap.set('cart_total_items', serviceQueries.CART_ITEMS_COUNT);
queryMap.set('order_promo_code', serviceQueries.SUCCESSFUL_OFFER_CODES);
queryMap.set('join_date', serviceQueries.GET_USER_CREATION_DATE);
queryMap.set('join_date_year_month', serviceQueries.GET_USER_CREATION_DATE);
queryMap.set('registration_date', serviceQueries.GET_USER_CREATION_DATE);
queryMap.set('length_of_relationship', serviceQueries.GET_USER_CREATION_DATE);

export { queryMap };
