import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class OutboundClickedUseCase {
    private eventRepository: EventRepository;
    private url: string;

    constructor(url: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.url = url;
    }

    public execute = () => {
        const outboundPayload = {
            event_name: 'outbound_click',
            event_category: 'outbound_link_click',
            event_action: this.url,
            event_label: this.url
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.OUTBOUND_CLICKED,
            type: 'link',
            payload: outboundPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
