import { events } from '@estee/elc-service-bus';

import { EventRepository } from '../repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

let debounceTimeout: NodeJS.Timeout;
const debounceTimeInMs = 5;

const allPayloads: IOfferCodePayload[] = [];

export interface IOfferViewedProps {
    placement: string;
    promotionId: string;
    promotionName: string;
}

interface IOfferCodePayload {
    event_name: string;
    enh_action: string;
    event_category: string;
    event_action: string;
    event_label: string[];
    promo_pos: string[];
    promo_creative: string[];
    promo_name: string[];
    promo_id: string[];
}

const blankOfferCodePayload = (): IOfferCodePayload => ({
    event_name: 'content_module_view',
    enh_action: 'promo_view',
    event_category: 'ecommerce',
    event_action: 'promotion view',
    event_label: [],
    promo_pos: [],
    promo_creative: [],
    promo_name: [],
    promo_id: []
});

const mergeOfferCodePayload = (offerCodePayload: IOfferCodePayload[]) =>
    offerCodePayload.reduce(
        (acc, event) => ({
            event_name: acc.event_name,
            event_category: acc.event_category,
            event_action: acc.event_action,
            event_label: acc.event_label.concat(event.event_label),
            promo_pos: acc.promo_pos.concat(event.promo_pos),
            promo_creative: acc.promo_creative.concat(event.promo_creative),
            promo_name: acc.promo_name.concat(event.promo_name),
            promo_id: acc.promo_id.concat(event.promo_id)
        }),

        blankOfferCodePayload() as IOfferCodePayload
    );

export class OfferViewed {
    private eventRepository: EventRepository;
    private _promotionName: string;
    private _promotionId: string;

    constructor({ placement, promotionId, promotionName }: IOfferViewedProps) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this._promotionName = promotionName;
        this._promotionId = promotionId;
    }

    public execute = () => {
        const offerCodePayload: IOfferCodePayload = {
            event_name: 'content_module_click',
            enh_action: 'promo_view',
            event_category: 'ecommerce',
            event_action: 'promotion view',
            event_label: this._promotionName ? [`${this._promotionId}`] : ['content module click'],
            promo_pos: ['module'],
            promo_creative: ['Cart Overlay'],
            promo_name: [`${this._promotionName}`],
            promo_id: [`module-Cart Overlay-${this._promotionId}`]
        };
        allPayloads.push(offerCodePayload);

        clearTimeout(debounceTimeout);
        debounceTimeout = setTimeout(() => {
            const eventsToMerge = allPayloads.splice(0, allPayloads.length);

            const newEvent = new Event({
                id: this.eventRepository.generateId(),
                name: events.CART_OVERLAY_OFFER_CLICKED,
                type: 'link',
                payload: mergeOfferCodePayload(eventsToMerge)
            });

            this.eventRepository.addEventToQueue(newEvent);
        }, debounceTimeInMs);
    };
}
