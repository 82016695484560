import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class SubscribedForEmailsUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = () => {
        const subscribedForEmailsPayload = {
            event_name: 'signup',
            event_category: 'signup',
            event_action: 'email'
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SUBSCRIBED_FOR_EMAILS,
            type: 'link',
            payload: subscribedForEmailsPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
