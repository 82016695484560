import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { EventRepository } from '../repositories/EventRepository';
import { Event } from '../domain/entities/Event';
import { events } from '@estee/elc-service-bus';

export class AppointmentLocationSelectedUseCase {
    private readonly eventRepository: EventRepository;

    // eslint-disable-next-line @typescript-eslint/no-parameter-properties
    constructor(private readonly storeName: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const appointmentLocationSelectedPayload = {
            event_name: 'appointment booking',
            event_category: 'appointment booking',
            event_action: 'location selected',
            event_label: this.storeName
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.APPOINTMENT_LOCATION_SELECTED,
            type: 'link',
            payload: appointmentLocationSelectedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
