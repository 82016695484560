import { EventRepository } from '../repositories/EventRepository';
import { events, serviceQueries, query } from '@estee/elc-service-bus';
import { Event } from '../domain/entities/Event';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { mapProductSkusById } from '../../utils/dataAggregator';

interface IProductInfo {
    display_name: string;
    product_id: string;
}

export interface ISkuData {
    [key: string]: IProductInfo;
}

export const EVENT_NAME = 'notify_me';
export const EVENT_CATEGORY = 'ecommerce';
export const EVENT_ACTION = 'notify me - request';

export class ProductNotifyMeTriggeredUseCase {
    private eventRepository: EventRepository;
    private skuId: string;

    constructor(skudId: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.skuId = skudId;
    }

    public execute = async () => {
        // TODO: can this data be passed in instead of querying it?
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const data = <any>await query(serviceQueries.GET_PRODUCT_DATA, {
            filterBy: { skus: { skuIds: [this.skuId] } }
        });
        const skuIdMap: ISkuData = data.reduce(mapProductSkusById, {});
        const productInfo = skuIdMap[this.skuId];

        const productNotifyMeTriggeredPayload = {
            event_name: EVENT_NAME,
            event_action: EVENT_ACTION,
            event_category: EVENT_CATEGORY,
            event_label: `${productInfo.display_name} - PROD${productInfo.product_id}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.PRODUCT_NOTIFY_ME_TRIGGERED,
            type: 'link',
            payload: productNotifyMeTriggeredPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
