import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { EventRepository } from '../repositories/EventRepository';
import { Event } from '../domain/entities/Event';
import { IAccountSidebarShowcaseData } from '../interfaces/IAnalytics';

export class AccountSidebarShowcaseClickedUseCase {
    private eventRepository: EventRepository;
    private accountSidebarData: IAccountSidebarShowcaseData;

    constructor(accountSidebarData: IAccountSidebarShowcaseData) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.accountSidebarData = accountSidebarData;
    }

    public execute() {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: this.accountSidebarData.eventAction,
            type: 'link',
            payload: this.getAccountSidebarPayload()
        });

        this.eventRepository.addEventToQueue(newEvent);
    }

    private getAccountSidebarPayload() {
        const { key, item_title, eventAction } = this.accountSidebarData;

        return {
            event_name: 'content_module_click',
            enh_action: 'promo_click',
            event_category: 'ecommerce',
            event_action: eventAction,
            event_label: `banner click | [${item_title}]`,
            promo_pos: ['module'],
            promo_creative: [`${key}`],
            promo_name: [`${item_title}`],
            promo_id: [`module-${key}-${item_title}`]
        };
    }
}
