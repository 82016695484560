import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { IProductViewed } from '../interfaces/IAnalytics';

export class ProductViewedUseCase {
    private eventRepository: EventRepository;
    private productViewedPayload: IProductViewed;

    constructor(productViewedPayload: IProductViewed) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.productViewedPayload = productViewedPayload;
    }

    public execute = () => {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.ANALYTICS_PRODUCT_VIEWED,
            type: 'link',
            payload: {
                event_name: 'product_detail',
                event_category: 'ecommerce',
                event_action: 'spp',
                event_label: 'view_item',
                event_noninteraction: true,
                enh_action: 'detail'
            },
            data: {
                product: this.productViewedPayload
            }
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
