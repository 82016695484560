import { EventRepository } from '../../internal/repositories/EventRepository';
import * as views from '../../constants/Views';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export interface IOrderDetail {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export class OrderConfirmationPageLandedUseCase {
    private eventRepository: EventRepository;
    private payload: IOrderDetail;

    constructor(payload: IOrderDetail) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.payload = payload;
    }

    public execute = async () => {
        const pageDetails = {
            category_id: 'checkout',
            category_name: 'checkout',
            page_name: 'checkout | confirm',
            page_type: 'order',
            page_site_area: 'checkout',
            page_template: 'checkout'
        };

        const { order } = this.payload || {};
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const skus = order?.items?.map((item: any) => item.skuId);

        const orderConfirmationPayload = {
            order_currency_code: order?.currency?.isoCode,
            order_discount_amount: order?.totals?.totalDiscounts,
            order_grand_total: order?.totals?.totalWithTax,
            order_id: order?.id,
            order_merchandise_total: order?.totals?.subTotalWithTax,
            order_payment_type: this.payload?.paymentMethods?.[0],
            order_shipping_amount: order?.shippingInfo?.[0].priceWithTax,
            order_subtotal: order?.totals?.subTotalWithTax,
            order_tax_amount: order?.totals?.tax,
            order_total_product_units: order?.items?.length,
            order_type: order?.orderType,
            orders_compare: '',
            orders_total: order?.totals?.subTotalWithTax
        };

        const cartProductsPayload = {
            skuIds: skus
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: views.ORDER_CONFIRMATION_VIEW,
            type: 'view',
            payload: { ...pageDetails, ...orderConfirmationPayload },
            data: {
                cartProducts: cartProductsPayload
            }
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
