/* eslint-disable no-restricted-syntax */
import { setupServiceInfo } from '@estee/elc-universal-utils';
setupServiceInfo(__serviceInfo__);

export { AnalyticsService } from './service-setup/AnalyticsService';
export { AnalyticsDriver } from './internal/AnalyticsDriver';
export { DataAggregator } from './internal/DataAggregator';
export { queryMap } from './internal/QueryMap';
export { EventRepository } from './internal/repositories/EventRepository';
export { QueryProvidersMock } from './mock/QueryProvidersMock';
export { ServiceBusController } from './service-setup/ServiceBusController';
export { diContainer } from './service-setup/diContainer';
