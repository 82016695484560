/* eslint-disable  @typescript-eslint/no-explicit-any */

export const isTagId = (tags: string[]) => {
    return !isNaN(Number(tags[0]));
};

const getProductSkuMap = (product: any) => (skuData: any, sku: any) => {
    const { skus, ...productDetails } = product;
    const newSku = { ...productDetails, ...sku };

    return { ...skuData, [sku.skuId]: newSku };
};

export const mapProductSkusById = (data: any, product: any) => {
    const skusData = product.skus.reduce(getProductSkuMap(product), {});

    return { ...data, ...skusData };
};
