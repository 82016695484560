import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class PageViewUseCase {
    private eventRepository: EventRepository;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
    }

    public execute = async () => {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.ANALYTICS_PAGE_VIEWED,
            type: 'view'
        });
        this.eventRepository.prependEventToQueue(newEvent);
    };
}
