import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';

export class CreateAccountFailUseCase {
    private eventRepository: EventRepository;
    private error: string;

    constructor(error: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.error = error;
    }

    public execute = () => {
        const createAccountFailPayload = {
            event_name: 'create_account_fail',
            event_category: 'account',
            event_action: 'create account fail',
            event_label: `standard - ${this.error}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.CREATE_ACCOUNT_FAIL,
            type: 'link',
            payload: createAccountFailPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
