import { EventRepository } from '../../internal/repositories/EventRepository';
import { events, query, serviceQueries } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import * as routes from '../../constants/Routes';

export class AuthFailedUseCase {
    private eventRepository: EventRepository;
    private error: string;
    constructor(error: string) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.error = error;
    }

    public execute = async () => {
        const isCartPage = window.location.href.indexOf('cart') !== -1 ? true : false;
        // TODO: can this be passed in instead of querying for it?
        const routerStatus = await query(serviceQueries.GET_CURRENT_ROUTE);
        const currentRoute = isCartPage ? 'cart' : routerStatus.currentRoute;

        const authFailedPayload = {
            event_name: 'login_fail',
            event_category:
                currentRoute === routes.LOGIN
                    ? 'account'
                    : currentRoute === routes.CART
                    ? 'cart'
                    : 'checkout',
            event_action: 'login fail',
            event_noninteraction: 0,
            event_label: `standard - ${this.error}`
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.AUTH_FAILED,
            type: 'link',
            payload: authFailedPayload
        });
        this.eventRepository.addEventToQueue(newEvent);
    };
}
