(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("mobx"), require("ElcServiceBus"), require("HypoContainer"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "mobx", "ElcServiceBus", "HypoContainer"], factory);
	else if(typeof exports === 'object')
		exports["elc-service-analytics"] = factory(require("react"), require("mobx"), require("ElcServiceBus"), require("HypoContainer"));
	else
		root["elc-service-analytics"] = factory(root["ElcRuntime"]["React"], root["ElcRuntime"]["mobx"], root["ElcServiceBus"], root["ElcRuntime"]["HypoContainer"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_mobx__, __WEBPACK_EXTERNAL_MODULE__estee_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE_hypo_container__) {
return 