import { EventRepository } from '../repositories/EventRepository';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { events } from '@estee/elc-service-bus';

export interface ISearchTermSelected {
    searchTerm: string;
}

export class SearchTermSelectedUseCase {
    private eventRepository: EventRepository;
    private searchTerm: string;

    constructor(payload: ISearchTermSelected) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.searchTerm = payload.searchTerm;
    }

    public execute = async () => {
        const searchTermSelectedPayload = {
            event_name: 'onsite_search',
            event_category: 'onsite search',
            event_action: 'predictive text click',
            event_label: this.searchTerm
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SEARCH_TERM_SELECTED,
            type: 'link',
            payload: searchTermSelectedPayload
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
