import { EventRepository } from '../../internal/repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { root } from '@estee/elc-universal-utils';
import { ConfigStore } from '../../service-setup/ConfigStore';

export class ShippingAddressFilledUseCase {
    private eventRepository: EventRepository;
    private configStore: ConfigStore;

    constructor() {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.configStore = diContainer.get(serviceNames.configStore);
    }

    private getLocation = () => {
        const pathName = root.location.pathname;
        if (pathName.indexOf(this.configStore.config.routes.checkout) !== -1) {
            return 'checkout';
        } else if (pathName.indexOf(this.configStore.config.routes.settings) !== -1) {
            return 'account';
        } else {
            return '';
        }
    };

    public execute = () => {
        const shippingAddressFilled = {
            event_name: 'shipping_address',
            event_category: 'ecommerce',
            event_action: 'checkout',
            event_label: this.getLocation()
        };

        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SHIPPING_ADDRESS_FILLED,
            type: 'link',
            payload: shippingAddressFilled
        });

        this.eventRepository.addEventToQueue(newEvent);
    };
}
