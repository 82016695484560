import { AnalyticsDriver } from './AnalyticsDriver';
import { EventRepository } from './repositories/EventRepository';
import { observable, autorun } from 'mobx';
import { Event } from '../internal/domain/entities/Event';
import { IUtagData, BrowserStorage } from '@estee/elc-universal-utils';

export class EventSubmitter {
    @observable private analyticsDriver: AnalyticsDriver;
    @observable private eventRepository: EventRepository;
    @observable public isAnalyticsEnabled: boolean = false;

    constructor(analyticsDriver: AnalyticsDriver, eventRepository: EventRepository) {
        this.analyticsDriver = analyticsDriver;
        this.eventRepository = eventRepository;
        autorun(() => {
            this.trackEvents(
                this.eventRepository.hasEventsToSubmit,
                this.analyticsDriver.isLoaded,
                this.isAnalyticsEnabled
            );
        });
    }

    public updateUtagData = (payload: IUtagData, extend: boolean = false) => {
        Object.keys(payload).forEach((key) => {
            if (extend && typeof payload[key] === 'object') {
                const utagData = <string[]>this.analyticsDriver.utagData[key] || [];
                const payloadData = <string[]>payload[key] || [];
                this.analyticsDriver.utagData[key] = utagData.concat(payloadData);
            } else {
                this.analyticsDriver.utagData[key] = payload[key];
            }
        });

        if (!!this.analyticsDriver.utagData.product_impression_id) {
            this.analyticsDriver.utagData.product_impression_position = (<string[]>(
                this.analyticsDriver.utagData.product_impression_id
            )).map((pos, i) => i + 1);
        }
    };

    public enableAnalytics = () => {
        this.isAnalyticsEnabled = true;
    };

    public disableAnalytics = () => {
        this.isAnalyticsEnabled = false;
    };

    private isQACookieSet = () => BrowserStorage.getCookie('analyticsQA') === 'enabled';

    private logEvent = (payload: IUtagData) => {
        if (!this.isQACookieSet()) {
            return;
        }
        const tealiumEvents = sessionStorage.getItem('tealiumEvents');
        if (!tealiumEvents) {
            sessionStorage.setItem('tealiumEvents', JSON.stringify([payload]));
        } else {
            const tealiumEventsFromSessionStorage = JSON.parse(tealiumEvents);
            tealiumEventsFromSessionStorage.push(payload);
            sessionStorage.setItem(
                'tealiumEvents',
                JSON.stringify(tealiumEventsFromSessionStorage)
            );
        }
    };

    private transformPayload = (payload: IUtagData) => {
        return JSON.parse(JSON.stringify(payload));
    };

    public trackEvents = (
        hasEventsToSubmit: boolean,
        driverLoaded: boolean,
        isAnalyticsEnabled: boolean
    ) => {
        if (driverLoaded && hasEventsToSubmit && isAnalyticsEnabled) {
            this.eventRepository.completeEvents.forEach((event) => {
                if (event.type === 'view' && this.eventRepository.hasIncompleteDataEvents) {
                    return true;
                }
                if (event.type === 'link' && this.eventRepository.hasIncompleteViewEvents) {
                    return true;
                }
                void this.sendEvent(event, this.logEvent);
                this.eventRepository.removeEventFromQueue(event.id);
            });
        }
    };

    public sendEvent = async (event: Event, cb: Function) => {
        const payload = this.transformPayload({
            ...event.payload
        });

        if (event.type === 'link') {
            this.analyticsDriver.trackEvent(payload);
        } else if (event.type === 'data') {
            this.updateUtagData(this.transformPayload(event.payload), true);
        } else if (event.type === 'view') {
            this.updateUtagData(this.transformPayload(event.payload));
            this.analyticsDriver.pageTrack(payload);
        }

        cb(payload);
    };
}
