import { EventRepository } from '../repositories/EventRepository';
import { events } from '@estee/elc-service-bus';
import { diContainer, serviceNames } from '../../service-setup/diContainer';
import { Event } from '../domain/entities/Event';
import { IGenericAnalyticsData } from '../interfaces/IAnalytics';

export class SocialShareIconTriggeredUseCase {
    private eventRepository: EventRepository;
    private socialShareData: IGenericAnalyticsData;

    constructor(socialShareData: IGenericAnalyticsData) {
        this.eventRepository = diContainer.get(serviceNames.eventRepository);
        this.socialShareData = socialShareData;
    }

    public execute = () => {
        const newEvent = new Event({
            id: this.eventRepository.generateId(),
            name: events.SOCIAL_SHARE_ICON_TRIGGERED,
            type: 'link',
            payload: this.getSocialSharePayload()
        });

        this.eventRepository.addEventToQueue(newEvent);
    };

    private getSocialSharePayload() {
        const { eventLabel } = this.socialShareData;

        return {
            event_name: 'social share',
            event_category: 'social',
            event_action: 'share',
            event_label: eventLabel
        };
    }
}
